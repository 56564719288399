import React from 'react';
import './index.css';
import MainServices from './MainServices';

import Menu from './Menu';
import Search from './Search';


export default function Header() {

    return (
        <div className="jp_top_header_img_wrapper">
            <div className="jp_slide_img_overlay"></div>
            
            <Menu />
            
            <Search />

            <MainServices />
        </div>
    );
}