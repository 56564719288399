/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';

export default function SideBanner() {

    return (
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="jp_add_resume_wrapper">
                <div className="jp_add_resume_img_overlay"></div>
                <div className="jp_add_resume_cont">
                    <img src="images/content/resume_logo.png" alt="logo" />
                    <h4>Precisando de Serviços de Manutenção em Refrigeração?</h4>
                    <ul>
                        <li>
                            <LinkContainer to={'/Orcamento'}>
                                <a><i className="fa fa-plus-circle"></i> &nbsp;ORÇAMENTO</a>
                            </LinkContainer>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}