import React, { useEffect,  useState, useRef, Suspense, lazy, useContext } from 'react';
import { Routes, Route,  useNavigate, useLocation, useParams } from 'react-router-dom';
import { Context } from './Context/AuthContext';
import { GetServer } from './scripts/Loadbalancer';
import Home from './components/Home';

import Layout from './Layout';
import Cadastro from './components/Cadastro';
import Orcamento from './components/Orcamento';
import Page from './components/Page';
import Sobre from './components/Sobre';
import Privacidade from './components/Privacidade';
import Contato from './components/Contato';
import Parceiros from './components/Parceiros';
import Ranking from './components/Ranking';
import ComoFunciona from './components/ComoFunciona';
import ReclameAqui from './components/ReclameAqui';
import Planos from './components/Planos';
import Faq from './components/Faq';
import Videos from './components/Videos';


export default function RouteManager() {
    const location = useLocation();
    const isLogin = (location.pathname.toLocaleLowerCase().indexOf("login") > -1 || location.pathname === "/");
    const navigate = useNavigate();
    const routeRef = useRef("");


    useEffect(() => {
      GetServer();
    }, [])

    const ProtectedRoute = ({ children }) => {
        const { loading, authenticated } = useContext(Context);
        if (!authenticated && !loading && !isLogin) {
           navigate("/login");
        } else {
            return children;
        }

      };

    return (
    
      <Suspense fallback={<div>Carregando...</div>}>
        <Layout>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/Home" element={<Home />} />
                <Route exact path="/Home/:Affiliate" element={<Home />} />
                <Route exact path="/Cadastro" element={<Cadastro />} />
                <Route exact path="/Cadastro/:Affiliate" element={<Cadastro />} />
                <Route exact path="/Orcamento" element={<Orcamento />} />
                <Route exact path="/Orcamento/:Affiliate" element={<Orcamento />} />
                <Route exact path="/Page/:Id" element={<Page />} />
                <Route exact path="/Page/:Id/:Name" element={<Page />} />
                <Route exact path="/Sobre" element={<Sobre />} />
                <Route exact path="/Privacidade" element={<Privacidade />} />
                <Route exact path="/Contato" element={<Contato />} />
                <Route exact path="/Parceiros" element={<Parceiros />} />
                <Route exact path="/Ranking" element={<Ranking />} />
                <Route exact path="/ComoFunciona" element={<ComoFunciona />} />
                <Route exact path="/ReclameAqui" element={<ReclameAqui />} />
                <Route exact path="/ReclameAqui/:Guid" element={<ReclameAqui />} />
                <Route exact path="/Planos" element={<Planos />} />
                <Route exact path="/Faq" element={<Faq/>} />
                <Route exact path="/Videos" element={<Videos />} />
            </Routes>
        </Layout>
      </Suspense>
   
  );
}