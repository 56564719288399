/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from 'react';
import { FormGenerator } from '../../Form/FormGenerator';
import { Uuid, RemoveAcentos, Currency, IsCPF } from '../../../scripts/StringUtils';
import { Request } from '../../../scripts/Request';
import { cpf, cnpj } from 'cpf-cnpj-validator'; 
import { useNavigate } from 'react-router-dom';
import './index.css';



export default function Formulario({...props}) {
    const [pageLoading, setPageLoading] = useState(true);
    const [saveable, setSaveable] = useState(false);
    const [input, setInput] = useState({});
    const [horariosCheck, setHorariosCheck] = useState({
        seg: true,
        ter: true,
        qua: true,
        qui: true,
        sex: true,
        sab: true,
        dom: false
    });
    const _formUserRef = useRef();
    const _formLoadedRef = useRef();
    const _latlngRef = useRef({latitude: "", longitude:""});

    const navigate = useNavigate();

    //#region Effects
    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);
     //#endregion Effects


    //#region Form Handlers
     const endereco = async (value) => codigo_cidade(value);


     const codigo_cidade = async (value) => {
        CalcLatLng();
     }


    const uf_codigo = async (value) => {
        setPageLoading(true);
        await _formUserRef.current.Select("cidade_codigo", [], "api/Home/Cidades?uf_codigo=" + value, "GET", { }, "");
        setPageLoading(false);
    }


    const pessoa = async(value) => {
        await _formUserRef.current.Value("cpf", "");
        if(value === "0"){
            await _formUserRef.current.SetObject("cpf", {title: "CPF", mask: "999.999.999-99"});
        } else if(value === "1"){
            await _formUserRef.current.SetObject("cpf", {title: "CNPJ", mask: "99.999.999/9999-99"});
        }
    }


    const blur_email = async(args) => {
        let _cpf = await _formUserRef.current.GetValue("cpf");
        let _email = await _formUserRef.current.GetValue("email");

        if(_cpf !== "" && _email !== "") {
            Promise.resolve(new Request().Run("api/Cadastro/CheckUser", "POST", {cpf: _cpf, email: _email}, "", 0))
                .then((data) => {
                    if(data.toString() === "exists") {
                        setSaveable(false);
                        window.swal("Alerta", "Usuário já existente", "warning");
                        _formUserRef.current.Value("cpf", "");
                        _formUserRef.current.Value("email", "");
                    } else {
                        setSaveable(true);
                    }
                }).catch(() => {
                    
                });
        }
    }


    const blur_cpf = async(args) => {
        let _pessoa = await _formUserRef.current.GetValue("pessoa");

        if(!cpf.isValid(args) && _pessoa.id === 0){
            window.swal("Alerta", "CPF incorreto!", "warning");
        } else if(cnpj.isValid(args) && _pessoa.id === 1) {
            let _cpf = await _formUserRef.current.GetValue("cpf");
            let _email = await _formUserRef.current.GetValue("email");

            if(_cpf !== "" && _email !== "") {
                Promise.resolve(new Request().Run("api/Cadastro/CheckUser", "POST", {cpf: _cpf, email: _email}, "", 0))
                    .then((data) => {
                        if(data.toString() === "exists") {
                            setSaveable(false);
                            window.swal("Alerta", "Usuário já existente", "warning");
                            _formUserRef.current.Value("cpf", "");
                             _formUserRef.current.Value("email", "");
                        } else {
                            setSaveable(true);
                        }
                    }).catch(() => {
                        
                    });
            }
       } else if(!cnpj.isValid(args) && _pessoa.id === 1) {
            window.swal("Alerta", "CNPJ incorreto!", "warning");
        }
    }


    const OnFormLoading = async() => {
        setPageLoading(true);
    }


    const OnFormLoaded = async(fields) => {
        try {
            setTimeout(async() => {
                await _formUserRef.current.Select("uf_codigo", [], "/json/estados.json", "GET", "", "");
                _formUserRef.current.Value("uf_codigo", 26);
                await _formUserRef.current.Select("cidade_codigo", [], "api/Home/Cidades?uf_codigo=26", "GET", { }, "");
                let arr = _formUserRef.current.GetOptions("cidade_codigo");
                _formUserRef.current.Value("cidade_codigo", arr[0].id);

                CalcLatLng();
            }, 100);
        } catch(e) { }
        setPageLoading(false);
    }


    const _HandleHorarioCheck = (event) => {
        let _hor = Object.assign({}, horariosCheck);
        _hor[event.target.id] = event.target.checked;
        setHorariosCheck(_hor);
    }


    const _HandleHorarioChange = (e) => {
        setInput({...input, [e.currentTarget.id]: e.currentTarget.value});
    }


    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        if (!_formUserRef.current.RequiredValidation()) {
            window.swal("Alerta", "Preencha os campos obrigatórios!", "warning");
            return;
        }

        let _pessoa = _formUserRef.current.GetValue("pessoa");
        let _cpf = _formUserRef.current.GetValue("cpf");

        if(_pessoa.id === 0) {
            if(!cpf.isValid(_cpf)) {
                window.swal("Alerta", "CPF invalido!", "warning");
                return;
            }
        } else {
            if(!cnpj.isValid(_cpf)) {
                window.swal("Alerta", "Cnpj invalido!", "warning");
                return;
            }
        }
  
        let data = {};
        if(props.affiliate) data = Object.assign(data, {indicador: props.affiliate});
        if(_latlngRef.current) data = Object.assign(data, _latlngRef.current);

        for(let i=1; i<10; i++){
            let qvalue = window.$('input[name=q'+i+']:checked').val();
            if(qvalue) data = Object.assign(data, {["q"+i]: qvalue});
        }

        data = Object.assign(data, {["q10"]: window.$('select[name=q10]').val()});

        let services = [];
        for(let i=1; i<=25; i++){
            let svalue = window.$('#s'+i).is(':checked');
            if(svalue) services.push(window.$('#s'+i).val());
        }
        if(services.length > 0) data = Object.assign(data, {services: services});

        let dias = ["seg", "ter","qua", "qui", "sex", "sab", "dom"];
        let horarios = [];
        let hor = Object.assign({}, horariosCheck);
        dias.map((item, i) => {
            if(hor[item]) {
                horarios.push({weekday: i, ini: window.$('#'+item + '_ini').val(), fim: window.$('#'+item + '_fim').val()});
            }
        })
        data = Object.assign(data, {horarios: horarios});

        setPageLoading(true);
        let _result = await _formUserRef.current.Post("api/Cadastro/Save", data, '');
        console.log(_result);
        setPageLoading(false);

        if(_result.toString() === "exists") {
            window.swal("Alerta", "Usuário já existente", "warning");
        } else if(_result.toString() === "loaded") {
            window.swal("Sucesso", "Cadastro enviado", "success");
            window.$('html, body').animate({ scrollTop: 0 }, 'fast');
            navigate('/');
        }
    }


    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region geo
    const CalcLatLng = () => {
        let cidade = window.$("#cidade_codigo option:selected").text() || "";
        let uf_codigo =  window.$("#uf_codigo option:selected").val() || "";
        let ufObject = _formUserRef.current.GetObject("uf_codigo");
        let uf = ufObject.type.options.find(u => parseInt(u.id) === parseInt(uf_codigo)).sigla || "";
        let endereco = _formUserRef.current.GetValue("endereco") || "";
        let numero = _formUserRef.current.GetValue("numero") || "";
        let bairro = _formUserRef.current.GetValue("bairro") || "";

        if(cidade !== "" && uf !== "")
        {
            let address = cidade + "-" + uf;
            if(endereco !== "") address = endereco + ", " + cidade + "-" + uf;
            if(endereco !== "" && numero !== "") address = endereco + " " + numero + ", " + cidade + "-" + uf;
            if(endereco !== "" && numero !== "" && bairro !== "") address = endereco + " " + numero + ", " + bairro + ", " + cidade + "-" + uf;

            let geocoder = new window.google.maps.Geocoder();
            geocoder.geocode( { 'address': address}, function(results, status) {
                if (status === window.google.maps.GeocoderStatus.OK) {
                    let latitude = results[0].geometry.location.lat();
                    let longitude = results[0].geometry.location.lng();
                   _latlngRef.current = {latitude: latitude.toString(), longitude: longitude.toString()};
                } 
            });
        }
        
    }
    //#endregion geo


    //#region Tests
    /*document.addEventListener('keydown', async function (e) {
        var char = e.keyCode || e.which;
        var letra = String.fromCharCode(char).toLowerCase();
        if (letra === "t") {
            F("email", "fmantipuff@gmail.com");
            F("senha", "1234567");
            F("pessoa", "0");
            F("cpf", "31575780810");
            F("nome", "Felipe Martins");
            F("telefone", "(12) 9111-1111");
            F("whatsapp", "(12) 9111-1111");
            F("uf_codigo", "26");
            F("cidade_codigo", "9726");
            F("endereco", "Rua Cap Antonio Raponso Barreto");
            F("numero", "207");
            F("bairro", "Jd Bela Bista");
            F("cep", "12091-530");
            F("complemento", "Residencia");
            F("site", "https://lifequery.org");
            F("descricao", "Esse é apenas um teste");
            R("q1", 1);
            R("q2", 0);
            R("q3", 1);
            R("q4", 1);
            R("q5", 1);
            R("q6", 1);
            R("q7", 1);
            R("q8", 1);
            R("q9", 1);
            D("q10", 5);
            C("s1", true);
            C("s2", true);
            C("s3", true);
            C("s6", true);
            C("s9", true);
            C("s11", true);
            C("s15", true);
            CalcLatLng();
        }
    });*/


    const F =  async(key, val) => {
        await _formUserRef.current.Value(key, val);
    }

    const R = (key, val) =>{
        window.$('input:radio[name="'+key+'"]').filter('[value="' + val + '"]').attr('checked', true);
    }

    const D =  async(key, val) => {
        await window.$("#"+key).val(val);
    }

    const C =  async(key, val) => {
        await window.$("#"+key).attr('checked', val);
    }
    //#endregion Tests



    return (
        <div className="Formulario">
            <div className="register_section">
                <div className="register_tab_wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-md-offset-1">
                                <div role="tabpanel">

                                    <ul id="tabOne" className="nav register-tabs">
                                        <li className="active"><a href="#contentOne-1" data-toggle="tab">Cadastro de profissional <br />
                                                <span>Preencha todos campos obrigatórios</span></a>
                                        </li>
                                        <li><a><span style={{fontSize:'27px', float:'left'}}>30 </span> &nbsp;dias gratis <br /> 
                                        <span>Começe agora mesmo</span></a>
                                        </li>
                                    </ul>

                                  
                                    <div className="tab-content">
                                        <div className="tab-pane fade in active register_left_form" id="contentOne-1">
                                            {/*<div className="jp_regiter_top_heading">
                                                <p>Fields with * are mandetory </p>
                                            </div>*/}
                                            <div className="row">
                                                <form name="fuser" onSubmit={(event) => _HandleSubmit('rcadastro', event)} autoComplete="off" id="form-contact" className="" noValidate>
                                                    <FormGenerator ref={_formUserRef} name={'cadastro'} url={'/forms/cadastro.json'} execRef={Exec}></FormGenerator>
                                                    <div style={{clear:'both'}}></div>
                                                    <br /><br />

                                                    <div className={'ibox-content ibox-heading servicos_bar'} style={{ padding: '10px', fontSize: '14px' }}>
                                                        <small><i className="fa fa-stack-exchange"></i> Horários de atendimento</small>
                                                    </div>

                                                    <div className="horarios_info">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <input id="seg" type="checkbox" onChange={_HandleHorarioCheck} checked={horariosCheck.seg}/> &nbsp;Segunda-feira
                                                                    </td>
                                                                    <td>
                                                                        <input type="time" className="form-control" id="seg_ini" defaultValue="08:00" onChange={_HandleHorarioChange} disabled={!horariosCheck.seg} />
                                                                    </td>
                                                                    <td>
                                                                        <input type="time" className="form-control" id="seg_fim" defaultValue="18:00" onChange={_HandleHorarioChange} disabled={!horariosCheck.seg} />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <input id="ter" type="checkbox" onChange={_HandleHorarioCheck} checked={horariosCheck.ter}/> &nbsp;Terça-feira
                                                                    </td>
                                                                    <td>
                                                                        <input type="time" className="form-control" id="ter_ini" defaultValue="08:00" onChange={_HandleHorarioChange} disabled={!horariosCheck.ter} />
                                                                    </td>
                                                                    <td>
                                                                        <input type="time" className="form-control" id="ter_fim" defaultValue="18:00" onChange={_HandleHorarioChange} disabled={!horariosCheck.ter} />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <input id="qua" type="checkbox" onChange={_HandleHorarioCheck} checked={horariosCheck.qua}/> &nbsp;Quarta-feira
                                                                    </td>
                                                                    <td>
                                                                        <input type="time" className="form-control" id="qua_ini" defaultValue="08:00" onChange={_HandleHorarioChange} disabled={!horariosCheck.qua} />
                                                                    </td>
                                                                    <td>
                                                                        <input type="time" className="form-control" id="qua_fim" defaultValue="18:00" onChange={_HandleHorarioChange} disabled={!horariosCheck.qua} />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <input id="qui" type="checkbox" onChange={_HandleHorarioCheck} checked={horariosCheck.qui}/> &nbsp;Quinta-feira
                                                                    </td>
                                                                    <td>
                                                                        <input type="time" className="form-control" id="qui_ini" defaultValue="08:00" onChange={_HandleHorarioChange} disabled={!horariosCheck.qui} />
                                                                    </td>
                                                                    <td>
                                                                        <input type="time" className="form-control" id="qui_fim" defaultValue="18:00" onChange={_HandleHorarioChange} disabled={!horariosCheck.qui} />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <input id="sex" type="checkbox" onChange={_HandleHorarioCheck} checked={horariosCheck.sex}/> &nbsp;Sexta-feira
                                                                    </td>
                                                                    <td>
                                                                        <input type="time" className="form-control" id="sex_ini" defaultValue="08:00" onChange={_HandleHorarioChange} disabled={!horariosCheck.sex} />
                                                                    </td>
                                                                    <td>
                                                                        <input type="time" className="form-control" id="sex_fim" defaultValue="18:00" onChange={_HandleHorarioChange} disabled={!horariosCheck.sex} />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <input id="sab" type="checkbox" onChange={_HandleHorarioCheck} checked={horariosCheck.sab}/> &nbsp;Sabado
                                                                    </td>
                                                                    <td>
                                                                        <input type="time" className="form-control" id="sab_ini" defaultValue="09:00" onChange={_HandleHorarioChange} disabled={!horariosCheck.sab}  />
                                                                    </td>
                                                                    <td>
                                                                        <input type="time" className="form-control" id="sab_fim" defaultValue="13:00" onChange={_HandleHorarioChange} disabled={!horariosCheck.sab} />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <input id="dom" type="checkbox" onChange={_HandleHorarioCheck} checked={horariosCheck.dom}/> &nbsp;Domingo
                                                                    </td>
                                                                    <td>
                                                                        <input type="time" className="form-control" id="dom_ini" defaultValue="09:00" onChange={_HandleHorarioChange} disabled={!horariosCheck.dom} />
                                                                    </td>
                                                                    <td>
                                                                        <input type="time" className="form-control" id="dom_fim" defaultValue="13:00" onChange={_HandleHorarioChange} disabled={!horariosCheck.dom} />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div className={'ibox-content ibox-heading servicos_bar'} style={{ padding: '10px', fontSize: '14px' }}>
                                                        <small><i className="fa fa-stack-exchange"></i> Informações adicionais</small>
                                                    </div>

                                                    <div className="servicos_info">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        Você é assistência técnica autorizada?
                                                                    </td>
                                                                    <td>
                                                                        <input type="radio" name="q1" value="1" /> Sim
                                                                        <input type="radio" name="q1" value="0" /> Não
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Você é revenda autorizada de aparelhos de ar condicionado?
                                                                    </td>
                                                                    <td>
                                                                        <input type="radio" name="q2" value="1" /> Sim
                                                                        <input type="radio" name="q2" value="0" /> Não
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Você é instalador autorizado de ar condicionado?
                                                                    </td>
                                                                    <td>
                                                                        <input type="radio" name="q3" value="1" /> Sim
                                                                        <input type="radio" name="q3" value="0" /> Não
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Você possui loja fixa?
                                                                    </td>
                                                                    <td>
                                                                        <input type="radio" name="q4" value="1" /> Sim
                                                                        <input type="radio" name="q4" value="0" /> Não
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Você emite nota fiscal serviço?
                                                                    </td>
                                                                    <td>
                                                                        <input type="radio" name="q5" value="1" /> Sim
                                                                        <input type="radio" name="q5" value="0" /> Não
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Você possui curso técnico em refrigeração?
                                                                    </td>
                                                                    <td>
                                                                        <input type="radio" name="q6" value="1" /> Sim
                                                                        <input type="radio" name="q6" value="0" /> Não
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Você presta serviço de limpeza e higienização?
                                                                    </td>
                                                                    <td>
                                                                        <input type="radio" name="q7" value="1" /> Sim
                                                                        <input type="radio" name="q7" value="0" /> Não
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Você no serviço de limpeza e higienização remove o equipamento?
                                                                    </td>
                                                                    <td>
                                                                        <input type="radio" name="q8" value="1" /> Sim
                                                                        <input type="radio" name="q8" value="0" /> Não
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Você emite documentação PMOC?
                                                                    </td>
                                                                    <td>
                                                                        <input type="radio" name="q9" value="1" /> Sim
                                                                        <input type="radio" name="q9" value="0" /> Não
                                                                    </td>  
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Tempo de atuação no mercado
                                                                    </td>
                                                                    <td>
                                                                        <select id="q10" name="q10" className="form-control">
                                                                            <option value="-1">Menos de 1 ano</option>
                                                                            <option value="1">Mais de 1 ano</option>
                                                                            <option value="2">Mais de 2 anos</option>
                                                                            <option value="3">Mais de 3 anos</option>
                                                                            <option value="4">Mais de 4 anos</option>
                                                                            <option value="5">Mais de 5 anos</option>
                                                                            <option value="10">Mais de 10 anos</option>
                                                                            <option value="20">Mais de 20 anos</option>
                                                                        </select>
                                                                    </td>  
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    
                                                    <div className={'ibox-content ibox-heading servicos_bar'} style={{ padding: '10px', fontSize: '14px' }}>
                                                        <small><i className="fa fa-stack-exchange"></i> Presta serviços em:</small>
                                                    </div>

                                                    <div className="servicos_info">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <input type="checkbox" id="s1" value="1"/> Ar-condicionado
                                                                    </td>
                                                                    <td>
                                                                        <input type="checkbox" id="s2" value="2"/> Camara fria
                                                                    </td>
                                                                    <td>
                                                                        <input type="checkbox" id="s3" value="3"/> Balcão frigorifico
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <input type="checkbox" id="s4" value="4" /> Bebedouro
                                                                    </td>
                                                                    <td>
                                                                        <input type="checkbox" id="s5" value="5" /> Exaustor
                                                                    </td>
                                                                    <td>
                                                                        <input type="checkbox" id="s6" value="6" /> Geladeira
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <input type="checkbox" id="s7" value="7" /> Freezer
                                                                    </td>
                                                                    <td>
                                                                        <input type="checkbox" id="s8" value="8" /> Frigobar
                                                                    </td>
                                                                    <td>
                                                                        <input type="checkbox" id="s9" value="9" /> Máquina de lavar
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <input type="checkbox" id="s10" value="10" /> Secadora de roupa
                                                                    </td>
                                                                    <td>
                                                                        <input type="checkbox" id="s11" value="11" /> Microondas
                                                                    </td>
                                                                    <td>
                                                                        <input type="checkbox" id="s12" value="12" /> Adega
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <input type="checkbox" id="s13" value="13" /> Máquina de gelo
                                                                    </td>
                                                                    <td>
                                                                        <input type="checkbox" id="s14" value="14" /> Máquina de sorvete
                                                                    </td>
                                                                    <td>
                                                                        <input type="checkbox" id="s15" value="15" /> Cervejeira
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <input type="checkbox" id="s16" value="16" /> Chopeira
                                                                    </td>
                                                                    <td>
                                                                        <input type="checkbox" id="s17" value="17" /> Iogurteira
                                                                    </td>
                                                                    <td>
                                                                        <input type="checkbox" id="s18" value="18" /> Fritadeira
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <input type="checkbox" id="s19" value="19" /> Ilha de congelamento
                                                                    </td>
                                                                    <td>
                                                                        <input type="checkbox" id="s20" value="20" /> Ultra congelador
                                                                    </td>
                                                                    <td>
                                                                        <input type="checkbox" id="s21" value="21" /> Ultra caneca
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <input type="checkbox" id="s22" value="22" /> Refrigerador inox
                                                                    </td>
                                                                    <td>
                                                                        <input type="checkbox" id="s23" value="23" /> Ar de painel
                                                                    </td>
                                                                    <td>
                                                                        <input type="checkbox" id="s24" value="24" /> Sala climatizada
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <input type="checkbox" id="s25" value="25" /> Estufa
                                                                    </td>
                                                                    <td>
                                                                        <input type="checkbox" id="s26" value="26" /> Cortina de Ar
                                                                    </td>
                                                                    <td>
                                                                        
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>


                                                    <div className="login_btn_wrapper register_btn_wrapper login_wrapper">
                                                        <button type="submit" className="btn btn-primary login_btn" disabled={!saveable}>Cadastrar</button>
                                                    </div>
                                                </form>
                                               
                                            </div>
                                            <div className="login_message">
                                                <br />
                                                <p>Já é um profissional inscrito? <a href="https://play.google.com/store/apps/details?id=org.lifequery.i2play" target="_blank" > Baixe o app agora! </a> </p>
                                            </div>
                                        </div>

                                        
                                    </div>
                                    <p className="btm_txt_register_form">O applicativo é gratuito para download, novos usuários recebem 30 dias sem custo para uso dos serviços pagos, após esse período é necessário adquirir um plano de serviços.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}