import React from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';



function MapComponent({...props}) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAsZ0bbGGgLsLzpuxbiuJ774xHQfL5sObM"
  })

  const {latitude, longitude, nome} = props;


  const containerStyle = {
    width: '100%',
    height: '400px'
  };
  
  const center = {
    lat: latitude || 0,
    lng: longitude || 0
  };

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
    setTimeout(() => {
      map.setZoom(15);

      
    }, 300);
  
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <Marker position={center} title={nome}/>
        <></>
      </GoogleMap>
  ) : <></>
}

export default React.memo(MapComponent)