/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import ReactGA from 'react-ga';
import { Request } from '../../scripts/Request';
import { useParams } from 'react-router-dom';
import './index.css';


import Menu from '../Header/Menu';
import Breadcrumb from '../Header/Breadcrumb';
import Loja from './Loja';


export default function Page({...props}) {
    const [pageLoading, setPageLoading] = useState(false);
    const [breadcrumb, setBreadcrumb] = useState([
        {link:"/", name:"Início"},
        {link:"", name:"Carregando..."}
    ]);
    const [info, setInfo] = useState(null);
    let { Id } = useParams();


    const timerIdleRef = useRef(new Date());
    const loadedRef = useRef(false);

    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        let timer = setTimeout(() => { Init() }, 1000);

        return () => {
            clearTimeout(timer);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    }, []);


    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);
    //#endregion Effects


    //#region Init
     const Init = () => {
        if(!loadedRef.current) {
            loadedRef.current = true;
            LoadLoja();
        }
     }
    //#endregion Init


    //#region Load
     const LoadLoja = () => {
        setPageLoading(true);
        Promise.resolve(new Request().Run("api/Page/Loja?loja_id=" + Id, "GET", {}, "", 60000))
            .then((data) => {
                setInfo(data);
                setBreadcrumb([
                    {link:"/", name:"Início"},
                    {link:"", name:data.nome}
                ]);
                setPageLoading(false);
            }).catch(() => {
                setPageLoading(false);
            });
     }
    //#endregion Load

    return (
        <>
            <Menu />
            <Breadcrumb title="Prestador de serviço" list={breadcrumb} />
            <Loja info={info} />
        </>
    )
}