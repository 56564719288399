/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from 'react';
import { FormGenerator } from '../../Form/FormGenerator';
import { Uuid, RemoveAcentos, Currency, IsCPF } from '../../../scripts/StringUtils';
import { Cache } from '../../../scripts/Cache.js';
import { Request } from '../../../scripts/Request';
import ReactGA from 'react-ga';
import { useNavigate } from 'react-router-dom';
import { requestForToken } from '../../../firebaseInit';
import './index.css';


export default function Formulario({...props}) {
    const [pageLoading, setPageLoading] = useState(false);
    const [saveable, setSaveable] = useState(true);
    const [notifyble, setNotifyable] = useState(false);
    const [lojas, setLojas] = useState([]);
    const [input, setInput] = useState({});
    const [isTokenFound, setTokenFound] = useState(false);
    const [favorites, setFavorites] = useState([]);

    const _formUserRef = useRef();
    const _formLoadedRef = useRef();
    const _latlngRef = useRef({latitude: "", longitude:""});;

    const navigate = useNavigate();



   //#region Effects
   useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        //Cache.JSet("favorites",[]);
        let _favorites = Cache.JGet("favorites");
        //console.log(_favorites);
        if(_favorites === null) _favorites = [];
        setFavorites(_favorites);
    }, []);

    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
    }, [pageLoading]);
    //#endregion Effects


    //#region Loaders
    const LoadLojas = (search) => {
        setPageLoading(true);
        Promise.resolve(new Request().Run("api/Orcamento/Lojas", "POST", search, "", 0))
            .then((data) => {
                setLojas(data);
                setPageLoading(false);
            }).catch(() => {
                setPageLoading(false);
            });
     }
    //#endregion Loaders


    //#region Form Handlers
    const endereco = async (value) => {
        let _nome =  await _formUserRef.current.GetValue("nome");
        if(_nome !== "" && _nome !== undefined && _nome !== null && value !== "" && value !== undefined && value !== null)
        {
            setSaveable(true);
        }
        else 
        {
            setSaveable(false);
        }
        codigo_cidade(value)
    };


    const codigo_cidade = async (value) => {
       CalcLatLng();
    }

    const servico_id = async (value) => {
        if(value === "99") {
            await _formUserRef.current.SetObject("servico", {visible: true});
        } else {
            await _formUserRef.current.SetObject("servico", {visible: false});
        }
    }


    const aparelho_id = async (value) => {
        if(value === "1") {
            await _formUserRef.current.SetObject("modelo_id", {visible: true});
            await _formUserRef.current.SetObject("btus", {visible: true});
        } else {
            await _formUserRef.current.SetObject("modelo_id", {visible: false});
            await _formUserRef.current.SetObject("btus", {visible: false});
        }
    }


    const uf_codigo = async (value) => {
        setPageLoading(true);
        await _formUserRef.current.Select("cidade_codigo", [], "api/Home/Cidades?uf_codigo=" + value, "GET", { }, "");
        let _codigo_cidade = await _formUserRef.current.GetValue("cidade_codigo");
        cidade_codigo(_codigo_cidade);
        setPageLoading(false);
    }


    const cidade_codigo = async (value) => {
        let _uf_cidade = await _formUserRef.current.GetValue("uf_codigo").id;
        LoadLojas({uf_codigo: _uf_cidade, cidade_codigo: value});
    }



    const OnFormLoading = async() => {
        setPageLoading(true);
    }


    const OnFormLoaded = async(fields) => {
        try {
            setTimeout(async() => {
                await _formUserRef.current.Select("uf_codigo", [], "/json/estados.json", "GET", "", "");
                _formUserRef.current.Value("uf_codigo", 26);
                await _formUserRef.current.Select("cidade_codigo", [], "api/Home/Cidades?uf_codigo=26", "GET", { }, "");
                let arr = _formUserRef.current.GetOptions("cidade_codigo");
                _formUserRef.current.Value("cidade_codigo", arr[0].id);
                //cidade_codigo(arr[0].id);
                CalcLatLng();
            }, 100);
        } catch(e) { }
        setPageLoading(false);
    }


    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        if (!_formUserRef.current.RequiredValidation()) {
            window.swal("Alerta", "Preencha os campos obrigatórios!", "warning");
            return;
        }

  
        let data = {};
        if(props.affiliate) data = Object.assign(data, {affiliate_id: props.affiliate});
        if(_latlngRef.current) data = Object.assign(data, _latlngRef.current);

        let isNotify = {...notifyble};
        console.log("isNotify", isNotify);
        if(isNotify) {
            let token = Cache.Get("firebase_token");
            if(token !== "" && token !== null && token !== undefined){
                data = Object.assign(data, {token: token});
            }
        }
        


        setPageLoading(true);
        let _result = await _formUserRef.current.Post("api/Orcamento/Save", data, '');
        console.log(_result);
        setPageLoading(false);

        if(_result.toString() === "loaded") {
            window.swal("Sucesso", "Solicitação de orçamento enviada, aguarde o contato alguns prestadores de serviço", "success");
            window.$('html, body').animate({ scrollTop: 0 }, 'fast');
            navigate('/');
        }
    }


    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleAddFavorite = (loja_id) => {
        let _favorites = [...favorites];
        let _index = _favorites.findIndex(v => parseInt(v) === parseInt(loja_id));
        if( _index > -1) {
            _favorites.splice(_index, 1);
        } else {
            _favorites.push(loja_id);
        }
        Cache.JSet("favorites", _favorites);
        //console.log(Cache.JGet("favorites"));
        setFavorites(_favorites);
    }


    const _HandleCheckFavorite = (loja_id) => {
        try {
            let _favorites = [...favorites];
            return ( _favorites.findIndex(v => v === loja_id) > -1 ? 'fa fa-heart' : 'fa fa-heart-o');
        } catch(err) {
            return 'fa fa-heart-o';
        }
     }


     const _HandleNotifyMe = async(event) => {
        let data = await requestForToken(setTokenFound);
        if (data) {
            console.log("Token is", data);
            Cache.Set("firebase_token", data);
        }
        setNotifyable(event.target.checked);
     }
    //#endregion Handlers


    //#region geo
    const CalcLatLng = () => {
        let cidade = window.$("#cidade_codigo option:selected").text() || "";
        let uf_codigo =  window.$("#uf_codigo option:selected").val() || "";
        let ufObject = _formUserRef.current.GetObject("uf_codigo");
        let uf = ufObject.type.options.find(u => parseInt(u.id) === parseInt(uf_codigo)).sigla || "";
        let endereco = _formUserRef.current.GetValue("endereco") || "";
        let numero = _formUserRef.current.GetValue("numero") || "";
        let bairro = _formUserRef.current.GetValue("bairro") || "";

        if(cidade !== "" && uf !== "")
        {
            let address = cidade + "-" + uf;
            if(endereco !== "") address = endereco + ", " + cidade + "-" + uf;
            if(endereco !== "" && numero !== "") address = endereco + " " + numero + ", " + cidade + "-" + uf;
            if(endereco !== "" && numero !== "" && bairro !== "") address = endereco + " " + numero + ", " + bairro + ", " + cidade + "-" + uf;

            let geocoder = new window.google.maps.Geocoder();
            geocoder.geocode( { 'address': address}, function(results, status) {
                if (status === window.google.maps.GeocoderStatus.OK) {
                    let latitude = results[0].geometry.location.lat();
                    let longitude = results[0].geometry.location.lng();
                   _latlngRef.current = {latitude: latitude, longitude: longitude};
                } 
            });
        }
        
    }
    //#endregion geo


    //#region Tests
     /*document.addEventListener('keydown', async function (e) {
        var char = e.keyCode || e.which;
        var letra = String.fromCharCode(char).toLowerCase();
        if (letra === "t") {
            F("servico_id", "1");
            F("aparelho_id", "1");
            F("modelo_id", "1");
            F("problema_id", "1");
            F("btus", "12");
            F("obs", "Apenas um teste");
            F("nome", "Felipe Martins");
            F("email", "fmantipuff@gmail.com");
            F("whatsapp", "(12) 98122-1043");
            await F("uf_codigo", "26");
            await F("cidade_codigo", "9726");
            F("endereco", "Rua cap Antonio Raposo Barreto");
            F("numero", "17");
            F("bairro", "Jardim Bela Vista");
            F("referencia", "residencia");
        }
    });*/


    const F =  async(key, val) => {
        try {
            await _formUserRef.current.Value(key, val);
        } catch(e) {}
    }

    const R = (key, val) =>{
        window.$('input:radio[name="'+key+'"]').filter('[value="' + val + '"]').attr('checked', true);
    }

    const D =  async(key, val) => {
        await window.$("#"+key).val(val);
    }

    const C =  async(key, val) => {
        await window.$("#"+key).attr('checked', val);
    }
    //#endregion Tests



    return (
        <div className="Formulario">
            <div className="register_section">
                <div className="register_tab_wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="orcamento-banner">
                                    {
                                        lojas.length > 0 ? 
                                        (
                                            lojas.slice(0, 8).map((loja, j) => (
                                                <div key={'loja'+j}>
                                                   <div className={'jp_job_post_main_wrapper_cont' + (j===0?'':' jp_job_post_main_wrapper_cont2')}>
                                                        <div className="jp_job_post_main_wrapper">
                                                            <div className="row">
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                                   
                                                                    <div className="_jp_job_post_right_cont">
                                                                        <h4>{loja.nome}</h4>
                                                                        <p>{loja.endereco}{loja.numero===''?'':', '} {loja.numero}</p>
                                                                        <ul>
                                                                            <li><i className="fa fa-phone"></i>&nbsp; {loja.telefone}</li>
                                                                            <li><i className="fa fa-map-marker"></i>&nbsp; {loja.cidade}-{loja.uf}</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                    <div className="jp_job_post_right_btn_wrapper">
                                                                        <ul>
                                                                            <li><a onClick={() => _HandleAddFavorite(loja.loja_id)}><i className={_HandleCheckFavorite(loja.loja_id)}></i></a></li>
                                                                            <li><a><i className="fa fa-star"></i> {loja.stars} estrelas</a></li>
                                                                            <li><a>{(loja.opened===1?'Aberto Agora':'Fechado')}</a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="jp_job_post_keyword_wrapper">
                                                            <ul>
                                                                <li><i className="fa fa-tags"></i>Serviços em:</li>
                                                                {
                                                                    loja.keywords !== null ? (
                                                                    loja.keywords.slice(0, 3).map((keyw, k) => (
                                                                        <li key={'kw'+k}><a>{keyw}{k===2?'...':','}</a></li>
                                                                    ))): (<></>)
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) 
                                        : ( <img src="/images/orcamento_banner.jpg" alt=""/>)
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div role="tabpanel">

                                    <ul id="tabOne" className="nav register-tabs">
                                        <li className="active"><a href="#contentOne-1" data-toggle="tab">Informações do orçamento<br />
                                                <span>Preencha todos campos obrigatórios</span></a>
                                        </li>
                                
                                    </ul>

                                  
                                    <div className="tab-content">
                                        <div className="tab-pane fade in active register_left_form" id="contentOne-1">
                                            <div className="row">
                                                <form name="fuser" onSubmit={(event) => _HandleSubmit('orcamento', event)} autoComplete="off" id="form-contact" className="" noValidate>
                                                    <FormGenerator ref={_formUserRef} name={'orcamento'} url={'/forms/orcamento.json'} execRef={Exec}></FormGenerator>
                                                    <div style={{clear:'both'}}></div>
                                                    <div style={{padding:'5px;', marginTop:'25px'}}>
                                                        <label><input type="checkbox" onClick={_HandleNotifyMe}/> Receber notificações</label>
                                                    </div>
                                                    <br />

                                                    <div className="login_btn_wrapper register_btn_wrapper login_wrapper">
                                                        <button type="submit" className="btn btn-primary login_btn" disabled={!saveable}>Enviar pedido de orçamento</button>
                                                    </div>
                                                </form>

                                            </div>
                                        </div>

                            
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}