/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import ReactGA from 'react-ga';
import './index.css';

import Menu from '../Header/Menu';
import Breadcrumb from '../Header/Breadcrumb';
import Banner from '../Banner';
import Share from '../Banner/Share';


export default function Videos() {
    const [pageLoading, setPageLoading] = useState(false);
    const [breadcrumb, setBreadcrumb] = useState([
        {link:"/", name:"Início"},
        {link:"", name:"Videos informativos"}
    ]);
    const [videos, setVideos] = useState([
        {group: 1, url:"gqLpxAOYrl4"}, 
        {group: 1, url:"hA0NA14tV-Y"}, 
        {group: 1, url:"-musEtQWFbU"}, 
        {group: 1, url:"VE7rf5E7y6s"}, 
        {group: 2, url:"MzUhYLaGxG0"}, 
        {group: 2, url:"fG8y0WkfuwY"}
    ]);

    const timerIdleRef = useRef(new Date());
    const loadedRef = useRef(false);

    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        let timer = setTimeout(() => { Init() }, 1000);

        return () => {
            clearTimeout(timer);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    }, []);

    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);
    //#endregion Effects


    //#region Init
     const Init = () => {
        if(!loadedRef.current) {
            loadedRef.current = true;
            
        }
    }
    //#endregion Init


    return (
        <>
            <Menu />
            <Breadcrumb title="Quem somos" list={breadcrumb} />

            <div className="jp_listing_single_main_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                            <div className="jp_listing_left_sidebar_wrapper">
                                <div className="jp_job_des">
                                    <h2>Videos informativos</h2>
                                    <br />
                                    
                                    <hr />
                                    <h3>Profissionais</h3>
                                    <br />

                                    {
                                        videos.filter(v => v.group === 1).map((item, i) => (
                                            (<>
                                                <iframe width="100%" height="350" src={'https://www.youtube.com/embed/'+item.url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                            </>)
                                        ))
                                    }

                                    <hr />
                                    <h3>Clientes</h3>
                                    <br />

                                    {
                                        videos.filter(v => v.group === 2).map((item, i) => (
                                            (<>
                                                <iframe width="100%" height="350" src={'https://www.youtube.com/embed/'+item.url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                            </>)
                                        ))
                                    }
                                </div>
                             
                                
                            </div>
                           
                            <Share />
                           
                        </div>
                        <Banner />
                    </div>
                </div>
            </div>
        </>
    )
}