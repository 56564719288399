/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import './index.css';


export default function Breadcrumb({...props}) {
    const { title, list } = props;


    return (
        <div className='Breadcrumb'>
            <div className="jp_tittle_main_wrapper">
                <div className="jp_tittle_img_overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="jp_tittle_heading_wrapper">
                                <div className="jp_tittle_heading">
                                    <h2>{title}</h2>
                                </div>
                                <div className="jp_tittle_breadcrumb_main_wrapper">
                                    <div className="jp_tittle_breadcrumb_wrapper">
                                        <ul>
                                            {
                                                list.map((item, i) => (
                                                    item.link === "" ? (<li key={'bc'+i}><a>{item.name}</a></li>) 
                                                    : (<li key={'bc'+i}><LinkContainer to={item.link}><a>{item.name}</a></LinkContainer> <i className="fa fa-angle-right"></i></li>)
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}