/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import '../../../js/owl.carousel.js';
import SideBanner from './SideBanner.js';
import Hiring from './Hiring.js';
import Lojas from './Lojas.js';
import UserChoose from './UserChoose.js';
import SideSpotlight from './SideSpotlight.js';
import SideCategories from './SideCategories.js';
import SideAdvice from './SideAdvice.js';
import SideCategoriesA from './SideCategoriesA.js';

import './index.css';


export default function Content() {
    
    useEffect(() => {
        window.$('.jp_spotlight_slider_wrapper .owl-carousel').owlCarousel({
            loop: true,
            margin: 10,
            autoplay:true,
            responsiveClass: true,
            smartSpeed: 1200,
            navText : ['<i class="fa fa-arrow-circle-o-left" aria-hidden="true"></i>','<i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>'],
            responsive: {
              0: {
                items: 1,
                nav: true
              },
              600: {
                items: 1,
                nav: true
              },
              1000: {
                items: 1,
                nav: true,
                loop: true,
                margin: 20
              }
            }
        })
     }, []);


    return(
        <div className="jp_first_sidebar_main_wrapper">
        <div className="container">
            <div className="row">
                <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                    <div className="row">
                        <Lojas />
                        <UserChoose />

                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 spacer"></div>

                        <Hiring />
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div className="jp_first_right_sidebar_main_wrapper">
                        <div className="row">
                            <SideBanner />
                            <SideSpotlight />
                            <SideCategories />
                            <SideAdvice />
                            <SideCategoriesA />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}