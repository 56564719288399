/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef, useCallback} from 'react';
import { HubObjects } from '../../../scripts/HubObjects.js';
import { Cache } from '../../../scripts/Cache.js';
import { Request } from '../../../scripts/Request';
import { LinkContainer } from 'react-router-bootstrap';
import '../../../js/owl.carousel.js';
import './Lojas.css';


//#region Components
const Group = ({children, ...props}) => {

    return (
        <div className="item" data-hash={'page-'+props.index}>
            {children} 
        </div>
    )
 }
//#endregion Components



export default function Lojas() {
    const component_name = "home/content/lojas";
    const [pageLoading, setPageLoading] = useState(false);
    const [favorites, setFavorites] = useState([]);
    const [lojas, setLojas] = useState([]);
    const [search, setSearch] = useState({});

    const typeSarch = useRef(0); //0-Ranking, 1-Favoritos, 2-Recentes

    const timerIdleRef = useRef(new Date());
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const pager = 5;

    //#region Effects
    useEffect(() => {
        /* window.$('.ss_featured_products .owl-carousel').owlCarousel({
            autoplayHoverPause:true,
            loop:true,
            margin:0,
            nav:true,
            autoplay:true,
            navText:["VOLTAR" , "PROX"],
            dots:true,
            smartSpeed: 3000,
            responsive:{
                0:{
                    items:1
                },
                600:{
                    items:1
                },
                1000:{
                    items:1
                }
            }
        });*/
        
        //Cache.JSet("favorites",[]);
        let _favorites = Cache.JGet("favorites");
        //console.log(_favorites);
        if(_favorites === null) _favorites = [];
        setFavorites(_favorites);

        let timer = setTimeout(() => { Init() }, 1000);

        return () => {
            Carousel("stop");
            clearTimeout(timer);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {

                    HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
     }, []);


     useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);
    //#endregion Effects


    //#region Init
     const Init = () => {
        RegisterHub();
        LoadLojas({}); //0-destaque 1-favorites 2-ranking
     }


     const Carousel = (action) => {
        console.log(action);
        if(action === "stop") {
            window.$('.ss_featured_products .owl-carousel').trigger('stop.owl.autoplay');
        } else {
            let owl = window.$('.ss_featured_products .owl-carousel').owlCarousel({
                autoplayHoverPause:true,
                stopOnHover:true,
                loop:true,
                margin:0,
                nav:true,
                autoplay:true,
                navText:["VOLTAR" , "PROX"],
                dots:true,
                smartSpeed: 3000,
                responsive:{
                    0:{
                        items:1
                    },
                    600:{
                        items:1
                    },
                    1000:{
                        items:1
                    }
                }
            });

            //owl.goTo(0)
            window.$('.ss_featured_products .owl-carousel').trigger('to.owl.carousel', 0);
        }
     }
    //#endregion Init


    //#region HubObjects
    const RegisterHub = () => {
        HubObjects.Set("HOME_CONTENT_LOJAS", search => {
            LoadLojas(search);
        }, component_name);
    }
    //#endregion HubObjects


    //#region Loaders
    const LoadLojas = (search) => {
        setPageLoading(true);
        setSearch(search);
        let _favorites = [...favorites];
        let _search = Object.assign(search, {favorites: _favorites.toString(), type: typeSarch.current})
        Promise.resolve(new Request().Run("api/Home/Lojas", "POST", _search, "", 0))
            .then((data) => {
                setLojas(data);
                setPageLoading(false);
                setTimeout(()=> {
                    Carousel();
                }, 200);
            }).catch(() => {
                setPageLoading(false);
            });
     }
    //#endregion Loaders


    //#region Handlers
     const _HandleAddFavorite = (loja_id) => {
        let _favorites = [...favorites];
        let _index = _favorites.findIndex(v => parseInt(v) === parseInt(loja_id));
        if( _index > -1) {
            _favorites.splice(_index, 1);
        } else {
            _favorites.push(loja_id);
        }
        Cache.JSet("favorites", _favorites);
        //console.log(Cache.JGet("favorites"));
        setFavorites(_favorites);
     }

     const _HandleTypeChange = (type) => {
        typeSarch.current = type;
        let _search = Object.assign({}, search);
        LoadLojas(_search);
     }

     const _HandleCheckFavorite = (loja_id) => {
        try {
            let _favorites = [...favorites];
            return ( _favorites.findIndex(v => v === loja_id) > -1 ? 'fa fa-heart' : 'fa fa-heart-o');
        } catch(err) {
            return 'fa fa-heart-o';
        }
     }
     
    //#endregion Handlers


    //#region Functions
    const Urlyze = (path) => {
        return path.toLowerCase().replaceAll(" ", "-").replaceAll("/", "-").replaceAll("\\", "-").replaceAll("\\", "-");
    }
    //#endregion Functions


     return(
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="cc_featured_product_main_wrapper">
                <div className="jp_hiring_heading_wrapper jp_job_post_heading_wrapper">
                    <h2>Profissionais encontrados</h2>
                </div>
                <ul className="nav nav-tabs" role="tablist">
                    <li role="presentation" className="active"><a aria-controls="best" role="tab" data-toggle="tab" onClick={()=>{_HandleTypeChange(0)}}>Ranking</a></li>
                    <li role="presentation"><a aria-controls="trand" role="tab" data-toggle="tab" onClick={()=>{_HandleTypeChange(1)}}>Favoritos</a></li>
                    <li role="presentation"><a aria-controls="best" role="tab" data-toggle="tab" onClick={()=>{_HandleTypeChange(2)}}>Recentes</a></li>
                </ul>
            </div>
            <div id="lojas" className="tab-content">
                <div role="tabpanel" className="tab-pane fade in active" id="best">
                    <div className="ss_featured_products">
                        <div className="owl-carousel owl-theme">
                            {
                                lojas.length > 0 ? (
                                    lojas.map((item, i) => (
                                        i%pager === 0 ? (
                                        <div key={'group'+i}>
                                            <Group index={Math.floor(i/pager)}> 
                                                {
                                                lojas.slice((Math.floor(i/pager)*5),((Math.floor(i/pager)+1)*5)).map((loja,j) => 
                                                (
                                                   <div key={'loj'+j}>
                                                        <div className={'jp_job_post_main_wrapper_cont' + (j===0?'':' jp_job_post_main_wrapper_cont2')}>
                                                            <div className="jp_job_post_main_wrapper">
                                                                <div className="row">
                                                                    <LinkContainer to={'/Page/' + loja.loja_id + "/" + Urlyze(loja.nome)} >
                                                                        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                                                            <div className="jp_job_post_side_img">
                                                                                <img src="images/content/job_post_img1.jpg" alt="post_img" />
                                                                            </div>
                                                                            <div className="jp_job_post_right_cont">
                                                                                <h4>{loja.nome}</h4>
                                                                                <p>{loja.endereco}{loja.numero===''?'':', '} {loja.numero}</p>
                                                                                <ul>
                                                                                    <li><i className="fa fa-phone"></i>&nbsp; {loja.telefone}</li>
                                                                                    <li><i className="fa fa-map-marker"></i>&nbsp; {loja.cidade}-{loja.uf}</li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </LinkContainer>
                                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                        <div className="jp_job_post_right_btn_wrapper">
                                                                            <ul>
                                                                                <li><a onClick={() => _HandleAddFavorite(loja.loja_id)}><i className={_HandleCheckFavorite(loja.loja_id)}></i></a></li>
                                                                                <li><a><i className="fa fa-star"></i> {loja.stars} estrelas</a></li>
                                                                                <li><a>{(loja.opened===1?'Aberto Agora':'Fechado')}</a></li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="jp_job_post_keyword_wrapper">
                                                                <ul>
                                                                    <li><i className="fa fa-tags"></i>Serviços em:</li>
                                                                    {
                                                                        loja.keywords !== null ? (
                                                                        loja.keywords.map((keyw, k) => (
                                                                            <li key={'kw'+k}><a>{keyw}{k===loja.keywords.length-1?'...':','}</a></li>
                                                                        ))): (<></>)
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                ))   
                                                }
                                            </Group>
                                        </div>) : (<></>)
                                    ))   
                                    

                                ) : (
                                    <div className='jumbotron'><center><i className="fa fa-user fa-2x"></i><br /><span style={{padding:'8px'}}>Nenhum prossional ou loja encontrados</span></center></div>
                                )
                            }
  

                        </div>
                    </div>
                    <div className="video_nav_img_wrapper">
                        <div className="video_nav_img">
                            <ul>
                                {
                                    lojas.length > 0 ? (
                                        lojas.map((item, i) => (
                                            i%pager === 0 ? (
                                                <li key={'pager'+i}><a className={'button secondary url owl_nav' + (i===0?' active':'')}  href={'#page-' + Math.floor(i/pager)}>{(Math.floor(i/pager)+1)}</a></li>
                                            ) : (<></>)
                                        ))
                                    ) : (<></>)
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                
       
            </div>
        </div>
     )
}