/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './SideCategories.css'

export default function SideCategories() {

    return (
        <div className="SideCategories col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="jp_rightside_job_categories_wrapper">
                <div className="jp_rightside_job_categories_heading">
                    <h4>Conectar Play</h4>
                </div>
                <div className="jp_rightside_job_categories_content">
                    <a href="https://conectarplay.com" target="_blank"><img src="/images/banners/257x459/1 - (257X 437).png" alt="" /></a>

                    {/*<ul>
                        <li><i className="fa fa-caret-right"></i> <a href="#">Graphic Designer <span>(214)</span></a></li>
                        <li><i className="fa fa-caret-right"></i> <a href="#">Engineering Jobs <span>(514)</span></a></li>
                        <li><i className="fa fa-caret-right"></i> <a href="#">Mainframe Jobs <span>(554)</span></a></li>
                        <li><i className="fa fa-caret-right"></i> <a href="#">Legal Jobs <span>(457)</span></a></li>
                        <li><i className="fa fa-caret-right"></i> <a href="#">IT Jobs <span>(1254)</span></a></li>
                        <li><i className="fa fa-caret-right"></i> <a href="#">R&D Jobs <span>(554)</span></a></li>
                        <li><i className="fa fa-caret-right"></i> <a href="#">Government Jobs <span>(350)</span></a></li>
                        <li><i className="fa fa-caret-right"></i> <a href="#">PSU Jobs <span>(221)</span></a></li>
                        <li><i className="fa fa-plus-circle"></i> <a href="#">View All Categories</a></li>
                     </ul>*/}
                </div>
            </div>
        </div>
    );
}