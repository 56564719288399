/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import './index.css';

import MapComponent from './MapComponent';



export default function Loja({...props}) {
    const [pageLoading, setPageLoading] = useState(false);
    
    const nome = props.info?.nome;
    const email = props.info?.email;
    const telefone = props.info?.telefone;
    const whatsapp = props.info?.whatsapp;
    const descricao = props.info?.descricao;
    const site = props.info?.site;
    const cidade = props.info?.cidade;
    const uf = props.info?.uf;
    const endereco = props.info?.endereco;
    const numero = props.info?.numero;
    const cep = props.info?.cep;
    const bairro = props.info?.bairro;
    const stars = props.info?.stars;
    const info = props.info?.info || [];
    const servicos = props.info?.servicos || [];
    const latitude = props.info?.latitude;
    const longitude = props.info?.longitude


    //#region Functions
    const PhoneFy = (num) => {
        try {
            return num.replaceAll("(","").replaceAll(")","").replaceAll("-","").replaceAll(".","").replaceAll(" ","");
        } catch(e) {
            return num;
        }
    }
    //#endregion Functions


    return (
        <div className="Page">
            <div className="jp_cp_profile_main_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="jp_cp_left_side_wrapper">
                                <div className="jp_cp_left_pro_wallpaper">
                                    <img src="images/content/cp1.png" alt="profile_img" />
                                    <h2>{nome}</h2>
                                    <p>Prestador de Serviço</p>
                                    {/*<ul>
                                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="fa fa-youtube-play"></i></a></li>
                                    </ul>*/}
                                </div>
                                <div className="jp_cp_rd_wrapper">
                                    <ul>
                                        <li><a><i className="fa fa-star"></i> &nbsp;{stars} estrelas</a></li>
                                        <li><a href={'tel:+55'+PhoneFy(telefone)}><i className="fa fa-phone"></i> &nbsp;Ligar agora</a></li>
                                        
                                    </ul>
                                    <hr />
                                    <hr />
                                </div>
                            </div>
                           
                            <div className="jp_add_resume_wrapper jp_job_location_wrapper jp_cp_left_ad_res">
                                <div className="jp_add_resume_img_overlay"></div>
                                <div className="jp_add_resume_cont">
                                    <img src="images/content/resume_logo.png" alt="logo" />
                                    <h4>Precisando de Serviços de Manutenção em Refrigeração</h4>
                                    <ul>
                                        <li><a href="#"><i className="fa fa-plus-circle"></i> &nbsp;ORÇAMENTO</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                            <div className="jp_cp_right_side_wrapper">
                                <div className="jp_cp_right_side_inner_wrapper">
                                    <h2>DETALHES DO PROFISSIONAL</h2>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="td-w25">Nome completo</td>
                                                <td className="td-w10">:</td>
                                                <td className="td-w65">{nome}</td>
                                            </tr>
                                            <tr>
                                                <td className="td-w25">Email</td>
                                                <td className="td-w10">:</td>
                                                <td className="td-w65">{email}</td>
                                            </tr>
                                            <tr>
                                                <td className="td-w25">Telefone</td>
                                                <td className="td-w10">:</td>
                                                <td className="td-w65">{telefone}</td>
                                            </tr>
                                            <tr>
                                                <td className="td-w25">Whatsapp</td>
                                                <td className="td-w10">:</td>
                                                <td className="td-w65">{whatsapp}</td>
                                            </tr>
                                            <tr>
                                                <td className="td-w25">Experiência</td>
                                                <td className="td-w10">:</td>
                                                <td className="td-w65">{info[info.length-1] === -1 ? "Menos de 1 ano" : (info[info.length-1] === 1 ? "Mais de um ano" : (info[info.length-1] > 1 ? "Mais de " + info[info.length-1] + " anos": ""))}</td>
                                            </tr>
                                            <tr>
                                                <td className="td-w25">Site</td>
                                                <td className="td-w10">:</td>
                                                <td className="td-w65">{site}</td>
                                            </tr>
                                            <tr>
                                                <td className="td-w25">Cidade</td>
                                                <td className="td-w10">:</td>
                                                <td className="td-w65">{cidade}-{uf}</td>
                                            </tr>
                                            <tr>
                                                <td className="td-w25">Endereço</td>
                                                <td className="td-w10">:</td>
                                                <td className="td-w65">{endereco}, {numero}</td>
                                            </tr>
                                            <tr>
                                                <td className="td-w25">Bairro</td>
                                                <td className="td-w10">:</td>
                                                <td className="td-w65">{bairro}</td>
                                            </tr>
                                            <tr>
                                                <td className="td-w25">CEP</td>
                                                <td className="td-w10">:</td>
                                                <td className="td-w65">{cep}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                                    <div className="jp_cp_accor_heading_wrapper">
                                        <h2>Descrição</h2>
                                        <p>{descricao}</p>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                                    <div className="accordion_wrapper abt_page_2_wrapper">
                                        <div className="panel-group" id="accordion_threeLeft">

                                            <div className="panel panel-default">
                                                <div className="panel-heading bell">
                                                    <h4 className="panel-title">
                                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordion_threeLeft" href="#collapseTwentyLeftThree" aria-expanded="false">
                                                            SERVIÇOS
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseTwentyLeftThree" className="panel-collapse collapse" aria-expanded="false" role="tablist">
                                                    <div className="panel-body">
                                                        <ul>
                                                        {
                                                            servicos.length > 0 ? 
                                                            (servicos.map((item, i) => (
                                                                <li key={'info'+i}>+ {item}</li>
                                                            ))) 
                                                            : (<></>)
                                                        }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading bell">
                                                    <h4 className="panel-title">
                                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordion_threeLeft" href="#collapseTwentyLeftThree2" aria-expanded="false">
                                                            INFORMAÇÕES
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseTwentyLeftThree2" className="panel-collapse collapse" aria-expanded="false" role="tablist">
                                                    <div className="panel-body">
                                                        <ul>
                                                        {
                                                            info.length > 0 ? 
                                                            (info.slice(0,8).map((item, i) => (
                                                                <li key={'info'+i}>+ {item}</li>
                                                            ))) 
                                                            : (<></>)
                                                        }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel panel-default">
                                                <div className="panel-heading bell">
                                                    <h4 className="panel-title">
                                                        <a className="collapsed" data-toggle="collapse" data-parent="#accordion_threeLeft" href="#collapseTwentyLeftThree3" aria-expanded="false">
                                                            LOCALIZAÇÃO
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseTwentyLeftThree3" className="panel-collapse collapse" aria-expanded="false" role="tablist">
                                                    <div className="_panel-body">
                                                        <MapComponent latitude={latitude} longitude={longitude} nome={nome}/>
                                                    </div>
                                                </div>
                                            </div>
                                         
                                        </div>
                                   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}