/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useRef} from 'react';
import { Request } from '../../../scripts/Request';

export default function MainServices() {
    
    return (
        <div className="jp_banner_jobs_categories_wrapper">
            <div className="container">
                <div className="jp_top_jobs_category_wrapper jp_job_cate_left_border jp_job_cate_left_border_bottom">
                    <div className="jp_top_jobs_category">
                        <i className="fa fa-tasks"></i>
                        <h3><a>Ar-condicionados</a></h3>
                        
                    </div>
                </div>
                <div className="jp_top_jobs_category_wrapper jp_job_cate_left_border_bottom">
                    <div className="jp_top_jobs_category">
                        <i className="fa fa-microchip"></i>
                        <h3><a>Geladeiras</a></h3>

                    </div>
                </div>
                <div className="jp_top_jobs_category_wrapper jp_job_cate_left_border_bottom">
                    <div className="jp_top_jobs_category">
                        <i className="fa fa-thermometer"></i>
                        <h3><a>Freezers</a></h3>

                    </div>
                </div>
                <div className="jp_top_jobs_category_wrapper jp_job_cate_left_border_res">
                    <div className="jp_top_jobs_category">
                        <i className="fa fa-inbox"></i>
                        <h3><a>Máquinas de lavar</a></h3>

                    </div>
                </div>
                <div className="jp_top_jobs_category_wrapper">
                    <div className="jp_top_jobs_category">
                        <i className="fa fa-shopping-basket"></i>
                        <h3><a>Secadoras</a></h3>

                    </div>
                </div>
                <div className="jp_top_jobs_category_wrapper">
                    <div className="jp_top_jobs_category">
                        <i className="fa fa-cube"></i>
                        <h3><a>Microondas</a></h3>

                    </div>
                </div>
            </div>
        </div>
    )
}