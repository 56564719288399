/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react';
import {  useParams } from 'react-router-dom';
import { Cache } from '../../scripts/Cache.js';
import ReactGA from 'react-ga';
import './index.css';

import Menu from '../Header/Menu'
import Breadcrumb from '../Header/Breadcrumb';
import Formulario from './Formulario';


export default function Cadastro() {
     //#region Effects
     useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])
    //#end region Effects

    //#region Affiliate
    const affiliateRef = useRef({affiliate: "", type:""})
    let { Affiliate } = useParams();


    if(Affiliate!=="" && Affiliate!==null &&Affiliate!==undefined) {
        Cache.Set("affiliate", Affiliate);
        affiliateRef.current.affiliate = Affiliate;
    } else {
        affiliateRef.current.affiliate = Cache.Get("affiliate");
    }
    //#endregion Affiliate


    const list = [
        {link:"/", name:"Início"},
        {link:"", name:"Cadastro de profissional"}
    ];

    return (
        <>
            <Menu />
            <Breadcrumb title="Cadastro" list={list}  />
            <Formulario affiliate={affiliateRef.current.affiliate}/>
            
        </>
    )
}