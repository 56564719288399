import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { Cache } from './scripts/Cache';

const firebaseConfig = {
    apiKey: "AIzaSyCX-bDhCB2lztCYVeULK7357DMuLaCOd6o",
    authDomain: "i2play-push-notifications.firebaseapp.com",
    projectId: "i2play-push-notifications",
    storageBucket: "i2play-push-notifications.appspot.com",
    messagingSenderId: "509271833738",
    appId: "1:509271833738:web:246daa3227c719bb138173",
    measurementId: "G-B337LEJKNX"
  };

  
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const publicKey = "BDw1d0pkLcMjascwBuPcNOy7W-I1OpYXDnIVSEv1jtKxlxYcVdst7QgZKbKmam1YKEgUAUdrrJ-38CbQQy3B0pw";

export const requestForToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await getToken(messaging, { vapidKey: publicKey });
    Cache.Set("firebase_token", currentToken);
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};


export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage((payload) => {
      resolve(payload);
    });
  });


 