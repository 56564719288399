import React, { useState, useRef, useEffect } from 'react';
import { Request } from '../../../scripts/Request';
import ReactGA from 'react-ga';
import { FormGenerator } from '../../Form/FormGenerator';
import { useNavigate } from 'react-router-dom';
import './index.css';


export default function Formulario() {
    const [pageLoading, setPageLoading] = useState(false);
    const [saveable, setSaveable] = useState(true);


    const _formUserRef = useRef();
    const _formLoadedRef = useRef();

    const navigate = useNavigate();


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
    }, [pageLoading]);
    //#endregion Effects


    //#region Form Handlers
    const uf_codigo = async (value) => {
        setPageLoading(true);
        await _formUserRef.current.Select("cidade_codigo", [], "api/Home/Cidades?uf_codigo=" + value, "GET", { }, "");
        let _codigo_cidade = await _formUserRef.current.GetValue("cidade_codigo");
        setPageLoading(false);
    }


    const OnFormLoading = async() => {
        setPageLoading(true);
    }


    const OnFormLoaded = async(fields) => {
        try {
            setTimeout(async() => {
                await _formUserRef.current.Select("uf_codigo", [], "/json/estados.json", "GET", "", "");
                _formUserRef.current.Value("uf_codigo", 26);
                await _formUserRef.current.Select("cidade_codigo", [], "api/Home/Cidades?uf_codigo=26", "GET", { }, "");
                let arr = _formUserRef.current.GetOptions("cidade_codigo");
                _formUserRef.current.Value("cidade_codigo", arr[0].id);
            }, 100);
        } catch(e) { }
        setPageLoading(false);
    }


    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        if (!_formUserRef.current.RequiredValidation()) {
            window.swal("Alerta", "Preencha os campos obrigatórios!", "warning");
            return;
        }

        let data = {uf: window.$("#uf_codigo option:selected").text() , cidade: window.$("#cidade_codigo option:selected").text()};

        setPageLoading(true);
        let _result = await _formUserRef.current.Post("api/Contato/Send", data , '');
        console.log(_result);
        setPageLoading(false);

        if(_result.toString() === "loaded") {
            window.swal("Sucesso", "Mensagem enviada com sucesso!", "success");
            window.$('html, body').animate({ scrollTop: 0 }, 'fast');
            navigate('/');
        }
    }


    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers
    


    //#region Tests
    /*document.addEventListener('keydown', async function (e) {
        var char = e.keyCode || e.which;
        var letra = String.fromCharCode(char).toLowerCase();
        if (letra === "t") {
            F("mensagem", "Apenas um teste");
            F("nome", "Felipe Martins");
            F("email", "fmantipuff@gmail.com");
            F("telefone", "(12) 98122-1043");
            await F("uf_codigo", "26");
            await F("cidade_codigo", "9726");
        }
    });*/

    const F =  async(key, val) => {
        try {
            await _formUserRef.current.Value(key, val);
        } catch(e) {}
    }

    const R = (key, val) =>{
        window.$('input:radio[name="'+key+'"]').filter('[value="' + val + '"]').attr('checked', true);
    }

    const D =  async(key, val) => {
        await window.$("#"+key).val(val);
    }

    const C =  async(key, val) => {
        await window.$("#"+key).attr('checked', val);
    }
    //#endregion Tests




    return (
        <>
            <div className="jp_contact_form_main_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="jp_contact_form_heading_wrapper">
                                <h2>Dúvidas e sugestões</h2>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                            <div className="jp_contact_form_box">
                                <form name="fuser" onSubmit={(event) => _HandleSubmit('orcamento', event)} autoComplete="off" id="form-contact" className="" noValidate>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    
                                        <FormGenerator ref={_formUserRef} name={'contato'} url={'/forms/contato.json'} execRef={Exec}></FormGenerator>
                                        <div style={{clear:'both'}}></div>
                                        
                                    </div>

                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="jp_contact_form_btn_wrapper">
                                            <ul>
                                                <li><button type="submit" className="btn_contato"><i className="fa fa-plus-circle"></i>&nbsp; ENVIAR</button></li>
                                            </ul>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="jp_contact_right_box_wrapper">
                                <div className="jp_contact_form_add_heading_wrapper">
                                    <h2>Outros contatos</h2>
                                </div>
                                <div className="jp_form_add_wrapper gc_map_add_wrapper1">
                                    <div className="jp_map_location_icon_wrapper">
                                        <i className="fa fa-map-marker"></i>
                                    </div>
                                    <div className="gc_map_location_icon_cont_wrapper">
                                        <h3>Rua Itaboaté, 86 – Res. Vila Olímpia – Taubaté – SP – CEP:12062-717</h3>
                                    </div>
                                </div>
                                {/*<div className="jp_form_add_wrapper gc_map_add_wrapper2">
                                    <div className="jp_map_location_icon_wrapper">
                                        <i className="fa fa-phone"></i>
                                    </div>
                                    <div className="gc_map_location_icon_cont_wrapper gc_map_location_icon_cont_wrapper2">
                                        <h3>(12) 3211-0390</h3>
                                    </div>
                                </div>*/}
                                <div className="jp_form_add_wrapper gc_map_add_wrapper2">
                                    <div className="jp_map_location_icon_wrapper">
                                        <i className="fa fa-whatsapp"></i>
                                    </div>
                                    <div className="gc_map_location_icon_cont_wrapper gc_map_location_icon_cont_wrapper3">
                                        <h3>(12) 98297-1638</h3>
                                    </div>
                                </div>
                                <div className="jp_form_add_wrapper gc_map_add_wrapper3">
                                    <div className="jp_map_location_icon_wrapper">
                                        <i className="fa fa-envelope"></i>
                                    </div>
                                    <div className="gc_map_location_icon_cont_wrapper gc_map_location_icon_cont_wrapper4">
                                        <h3> <a href="#">i2play@gtnp.com.br</a></h3>
                                    </div>
                                </div>
                                <div className="jp_contact_form_social_icon">
                                    <ul>
                                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="fa fa-pinterest-p"></i></a></li>
                                        <li className="hidden-xs"><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                        <li className="hidden-xs"><a href="#"><i className="fa fa-google-plus"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}