/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import ReactGA from 'react-ga';
import './index.css';

import Menu from '../Header/Menu';
import Breadcrumb from '../Header/Breadcrumb';
import Banner from '../Banner';
import Share from '../Banner/Share';


export default function Ranking() {
    const [pageLoading, setPageLoading] = useState(false);
    const [breadcrumb, setBreadcrumb] = useState([
        {link:"/", name:"Início"},
        {link:"", name:"Os melhores profissionais"}
    ]);

    const timerIdleRef = useRef(new Date());
    const loadedRef = useRef(false);

    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        let timer = setTimeout(() => { Init() }, 1000);

        return () => {
            clearTimeout(timer);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    }, []);

    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);
    //#endregion Effects


    //#region Init
     const Init = () => {
        if(!loadedRef.current) {
            loadedRef.current = true;
            
        }
    }
    //#endregion Init


    return (
        <>
            <Menu />
            <Breadcrumb title="Ranking" list={breadcrumb} />

            <div className="jp_listing_single_main_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                            <div className="jp_listing_left_sidebar_wrapper">
                                <div className="jp_job_des">
                                    <h2>Ranking de confiança - Como funcionará</h2>
                                    <br />
                                    <p>O ranking é um sistema de valorização para empresa e prestadores de serviços, onde características serão exaltadas gerando uma pontuação que são automaticamente convertidas em estrelas que te posicionam melhor diante dos demais cadastrados.</p>
                                    <p>Por isso no seu cadastro preste atenção as informações solicitadas, pois todas elas serão mostradas em uma pagina pessoal acessível para seus potenciais clientes. Após seu cadastro será gerada uma pagina pessoal (Link) com todos os seus dados graduação em estrelas, telefone de contato e o link para o cliente fazer orçamentos. Divulgue seu link gerado e receba orçamentos e divulgue a sua empresa de forma profissional.</p>
                                    <p>Quer se destacar em sua cidade, entre em contato com nosso departamento de marketing para potencializarmos o número de orçamentos que estará recebendo. Veja agora com nosso time através dos canais:  <b>i2play@gtnp.com.br</b> ou WhatsApp.</p>
                                    <p>Você também pode solicitar material de divulgação para divulgar em suas redes sociais, sites, blog, anúncios e colocar seu negócio em evidência!</p>
                                </div>
                             
                                
                            </div>
                           
                            <Share />
                           
                        </div>
                        <Banner />
                    </div>
                </div>
            </div>
        </>
    )
}