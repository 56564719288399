/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import ReactGA from 'react-ga';
import './index.css';

import Menu from '../Header/Menu';
import Breadcrumb from '../Header/Breadcrumb';
import Banner from '../Banner';
import Share from '../Banner/Share';


export default function Sobre() {
    const [pageLoading, setPageLoading] = useState(false);
    const [breadcrumb, setBreadcrumb] = useState([
        {link:"/", name:"Início"},
        {link:"", name:"Sobre a i2play"}
    ]);

    const timerIdleRef = useRef(new Date());
    const loadedRef = useRef(false);

    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        let timer = setTimeout(() => { Init() }, 1000);

        return () => {
            clearTimeout(timer);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    }, []);

    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);
    //#endregion Effects


    //#region Init
     const Init = () => {
        if(!loadedRef.current) {
            loadedRef.current = true;
            
        }
    }
    //#endregion Init


    return (
        <>
            <Menu />
            <Breadcrumb title="Quem somos" list={breadcrumb} />

            <div className="jp_listing_single_main_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                            <div className="jp_listing_left_sidebar_wrapper">
                                <div className="jp_job_des">
                                    <h2>Descrição da plataforma</h2>
                                    <br />
                                    <p>Chega de procurar profissionais de refrigeração em vários lugares, aqui você encontrará os melhores e mais capacitados profissionais do ramo de refrigeração, climatização, ar-condicionado e linha branca do Brasil.</p>
                                    <p>A <b>i2Play</b> foi desenvolvida para unir empresas e prestadores de serviços a seus clientes finais do ramo de refrigeração, climatização, ar-condicionado e linha branca. Aqui o cliente coloca a sua necessidade e recebe no seu whatsapp orçamentos de profissionais, cadastrados na plataforma I2PLAY. </p>
                                    <p>Na <b>i2Play</b> os clientes não precisam fazer cadastros, basta o cliente preencher um rápido formulário com a sua necessidade e poucos dados e em minutos começa a receber em seu whatsapp orçamentos direto dos prestadores de serviços, sem intermediários ou burocracia. </p>
                                    <p>Após o cliente ter feito a sua solicitação de orçamento, de forma gratuita, rápida e sem burocracia através de seu celular, tablet ou computador via internet, os prestadores de serviços e empresas que estiverem cadastrados na plataforma i2Play na cidade selecionada ou num raio de 50 km.</p>
                                    <p>O consumidor final acessa o link, preenche os dados e envia o orçamento informando o que precisa, a partir daí as empresas e profissionais da cidade selecionada ou área de atuação/atendimento (50km) receberão no celular via APP i2Play a notificação do orçamento solicitado.</p>
                                    <p>Para visualizar os orçamentos total e ter acesso ao whatsapp do cliente é preciso ser assinante de um plano que pode ser contratado de forma rápida e imediata dentro do próprio app <b>i2Play</b>. Planos a partir de R$ 11,90 por mês.</p>
                                    <p>A assinatura poderá ser realizada a qualquer momento e o desbloqueio é automático pela própria plataforma que identifica o pagamento e libera a função.</p>
                                    <p>O parceiro <b>i2Play</b>enviará o seu orçamento automaticamente para whatsapp do cliente que solicitou e seguirá a conversa via whatsapp diretamente com seu novo futuro cliente.</p>
                                    <p>A <b>i2Play</b>não se responsabiliza pela aprovação da proposta/orçamento enviado, ou execução do serviço, pois é apenas um canal de conexão, ligando o cliente ao prestador de serviço.</p>
                                </div>
                             
                                
                            </div>
                           
                            <Share />
                           
                        </div>
                        <Banner />
                    </div>
                </div>
            </div>
        </>
    )
}