/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect} from 'react';
import './SideSpotlight.css';

export default function SideSpotlight() {

    useEffect(() => {
        window.$('.jp_spotlight_slider_wrapper .owl-carousel').owlCarousel({
            stagePadding: 0,
            autoWidth:true,
            loop: true,
            margin: 0,
            autoplay:true,
            responsiveClass: true,
            smartSpeed: 2300,
            navText : ['<i class="fa fa-arrow-circle-o-left" aria-hidden="true"></i>','<i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>'],
            responsive: {
              0: {
                items: 4,
                nav: true,
                margin: 1
              },
              600: {
                items: 4,
                nav: true,
                margin: 1
              },
              1000: {
                items: 4,
                nav: true,
                loop: true,
                margin: 1
              }
            }
        })
     }, []);



    return (
         <div className="Spotlight col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="jp_spotlight_main_wrapper">
                <div className="spotlight_header_wrapper">
                    <h4>Banners</h4>
                </div>
                <div className="jp_spotlight_slider_wrapper">
                    <div className="owl-carousel owl-theme">
                        <div className="item" style={{width:'257px', height:'459px'}}>
                            <a href="https://doutordoarcondicionado.com.br/" target="_blank"><img src="images/banners/257x459/BANNER 01 - 257X459.jpg" alt="" /></a>
                        </div>

                        {/*
                        <div className="item" style={{width:'257px', height:'459px'}}>
                            <img src="images/banners/257x459/BPDAC  257 X 459 (1).png" alt="" />
                        </div>
                        */}

                        <div className="item" style={{width:'257px', height:'459px'}}>
                            <a href="https://conectarplay.com" target="_blank"><img src="images/banners/257x459/BANNER 02 - 257X459.jpg" alt="" /></a>
                        </div>


                        <div className="item" style={{width:'257px', height:'459px'}}>
                            <a href="https://conectarplay.com" target="_blank"><img src="images/banners/257x459/BPSOF 257 X 459 (01).png" alt="" /></a>
                        </div>


                  

                        {/*<div className="item" style={{width:'257px', height:'459px'}}>
                            <img src="images/banners/257x459/BPSOF 257 X 459 (03).png" alt="" />
                        </div>

                        <div className="item" style={{width:'257px', height:'459px'}}>
                            <img src="images/banners/257x459/BPSOF 257 X 459 (05).png" alt="" />
                        </div>*/}

                        <div className="item" style={{width:'257px', height:'459px'}}>
                            <a href="https://conectarplay.com" target="_blank"><img src="images/banners/257x459/BPSOF 257 X 459 (07).png" alt="" /></a>
                        </div>

                        {/*<div className="item">
                            <div className="jp_spotlight_slider_img_Wrapper">
                                <img src="images/content/spotlight_img.jpg" alt="spotlight_img" />
                            </div>
                            <div className="jp_spotlight_slider_cont_Wrapper">
                                <h4>HTML Developer (1 - 2 Yrs Exp.)</h4>
                                <p>Webstrot Technology Ltd.</p>
                                <ul>
                                    <li><i className="fa fa-cc-paypal"></i>&nbsp; $12K - 15k P.A.</li>
                                    <li><i className="fa fa-map-marker"></i>&nbsp; Caliphonia, PO 455001</li>
                                </ul>
                            </div>
                            <div className="jp_spotlight_slider_btn_wrapper">
                                <div className="jp_spotlight_slider_btn">
                                    <ul>
                                        <li><a href="#"><i className="fa fa-plus-circle"></i> &nbsp;ADD RESUME</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="jp_spotlight_slider_img_Wrapper">
                                <img src="images/content/spotlight_img.jpg" alt="spotlight_img" />
                            </div>
                            <div className="jp_spotlight_slider_cont_Wrapper">
                                <h4>HTML Developer (1 - 2 Yrs Exp.)</h4>
                                <p>Webstrot Technology Ltd.</p>
                                <ul>
                                    <li><i className="fa fa-cc-paypal"></i>&nbsp; $12K - 15k P.A.</li>
                                    <li><i className="fa fa-map-marker"></i>&nbsp; Caliphonia, PO 455001</li>
                                </ul>
                            </div>
                            <div className="jp_spotlight_slider_btn_wrapper">
                                <div className="jp_spotlight_slider_btn">
                                    <ul>
                                        <li><a href="#"><i className="fa fa-plus-circle"></i> &nbsp;ADD RESUME</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="jp_spotlight_slider_img_Wrapper">
                                <img src="images/content/spotlight_img.jpg" alt="spotlight_img" />
                            </div>
                            <div className="jp_spotlight_slider_cont_Wrapper">
                                <h4>HTML Developer (1 - 2 Yrs Exp.)</h4>
                                <p>Webstrot Technology Ltd.</p>
                                <ul>
                                    <li><i className="fa fa-cc-paypal"></i>&nbsp; $12K - 15k P.A.</li>
                                    <li><i className="fa fa-map-marker"></i>&nbsp; Caliphonia, PO 455001</li>
                                </ul>
                            </div>
                            <div className="jp_spotlight_slider_btn_wrapper">
                                <div className="jp_spotlight_slider_btn">
                                    <ul>
                                        <li><a href="#"><i className="fa fa-plus-circle"></i> &nbsp;ADD RESUME</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>*/}
                    </div>
                </div>
            </div>
        </div>
    );
}