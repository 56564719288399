/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import ReactGA from 'react-ga';
import { Request } from '../../scripts/Request';
import { Cache } from '../../scripts/Cache.js';
import { FormGenerator } from '../Form/FormGenerator';
import { useNavigate } from 'react-router-dom';
import './index.css';

import Menu from '../Header/Menu';
import Breadcrumb from '../Header/Breadcrumb';
import Banner from '../Banner';
import Share from '../Banner/Share';


export default function ReclameAqui() {
    const [pageLoading, setPageLoading] = useState(false);
    const [search, setSearch] = useState({ word: "" });
    const [names, setNames] = useState([]);
    const [selectedUser, setSelectedUser] = useState({ user_id: "", nome: "", cidade: "", uf: "" });
    const [isPositive, setIsPositive] = useState(0);
    const [isConserto, setIsConserto] = useState(0);
    const [comment, setComment] = useState("");
    const [saveable, setSaveable] = useState(true);
    const [breadcrumb, setBreadcrumb] = useState([
        {link:"/", name:"Início"},
        {link:"", name:"Avaliação de profissionais"}
    ]);

    
    const _formUserRef = useRef();
    const _formLoadedRef = useRef();

    const waitSearchRef = useRef(null);
    const timerIdleRef = useRef(new Date());
    const loadedRef = useRef(false);

    const navigate = useNavigate();


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        let timer = setTimeout(() => { Init() }, 1000);

        return () => {
            clearTimeout(timer);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    }, []);

    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);
    //#endregion Effects


    //#region Init
     const Init = () => {
        if(!loadedRef.current) {
            loadedRef.current = true;
            
        }
    }
    //#endregion Init


    //#region Handlers
    const _HandlerSearchChange = async (event) => {
        let _search = Object.assign({}, search);
        _search[event.target.id] = event.target.value;
        setSearch(_search);

        clearTimeout(waitSearchRef.current);

        let _word = event.target.value;
        setSelectedUser({ user_id: "", nome: "", cidade: "", uf: "" });
        setNames([]);

        if (_word.length > 3) {
            let _names = [...names];
            let _index = _names.findIndex(n => n.nome === event.target.value);

            //console.log(_index, _names);

            if (_index > -1) {
                //console.log(_names[_index].loja_id);
                setSelectedUser(_names[_index]);
            } else {
                waitSearchRef.current = setTimeout(() => {
                    _HandlerSearchTimerChange(_word);
                }, 700);
            }
        }
    }


    const _HandlerSearchTimerChange = async (word) => {
        window.w_loadObj("#word");

        await Promise.resolve(new Request().Run("api/ReclameAqui/Profissionais", "POST", { word: word }, ""))
            .then((data) => {
                setNames(data);
                window.w_unloadObj("#word");
            });
    
    }


    const _HandleRadioClick = (event) => {
        if(event.target.name==="p_o_n") {
            setIsPositive(parseInt(event.target.value));
        } else if(event.target.name==="c_o_l") {
            setIsConserto(parseInt(event.target.value));
        }
    }
    //#endregion Handlers


    //#region Form Handlers
    const uf_codigo = async (value) => {
        setPageLoading(true);
        await _formUserRef.current.Select("cidade_codigo", [], "api/Home/Cidades?uf_codigo=" + value, "GET", { }, "");
        let _codigo_cidade = await _formUserRef.current.GetValue("cidade_codigo");
        setPageLoading(false);
    }


    const OnFormLoading = async() => {
        setPageLoading(true);
    }


    const OnFormLoaded = async(fields) => {
        try {
            setTimeout(async() => {
                await _formUserRef.current.Select("uf_codigo", [], "/json/estados.json", "GET", "", "");
                _formUserRef.current.Value("uf_codigo", 26);
                await _formUserRef.current.Select("cidade_codigo", [], "api/Home/Cidades?uf_codigo=26", "GET", { }, "");
                let arr = _formUserRef.current.GetOptions("cidade_codigo");
                _formUserRef.current.Value("cidade_codigo", arr[0].id);
            }, 100);
        } catch(e) { }
        setPageLoading(false);
    }


    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();

        if (!_formUserRef.current.RequiredValidation()) {
            window.swal("Alerta", "Preencha os campos obrigatórios!", "warning");
            return;
        }

        let data = {uf: window.$("#uf_codigo option:selected").text() , cidade: window.$("#cidade_codigo option:selected").text()};

        data = Object.assign(data, {tipo: isPositive});
        if(isPositive===2){
            data = Object.assign(data, {conserto: isConserto});

            let services = [];
            if(isConserto === 1){
                for(let i=1; i<=15; i++){
                    let svalue = window.$('#c'+i).is(':checked');
                    if(svalue) services.push(window.$('#c'+i).val());
                }
            } else if(isConserto === 2) {
                for(let i=1; i<=9; i++){
                    let svalue = window.$('#l'+i).is(':checked');
                    if(svalue) services.push(window.$('#l'+i).val());
                }
            }
            if(services.length > 0) data = Object.assign(data, {services: services});
        }

        data = Object.assign(data, {loja: selectedUser});

        let token = Cache.Get("firebase_token");
        if(token !== "" && token !== null && token !== undefined){
            data = Object.assign(data, {token: token});
        }


        setPageLoading(true);
        let _result = await _formUserRef.current.Post("api/ReclameAqui/Send", data , '');
        console.log(_result);
        setPageLoading(false);

        if(_result.toString() === "loaded") {
            window.swal("Sucesso", "Avaliação enviada com sucesso!", "success");
            window.$('html, body').animate({ scrollTop: 0 }, 'fast');
            navigate('/');
        }
    }


    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers
    


    //#region Tests
    /*document.addEventListener('keydown', async function (e) {
        var char = e.keyCode || e.which;
        var letra = String.fromCharCode(char).toLowerCase();
        if (letra === "t") {
            F("mensagem", "Apenas um teste");
            F("nome", "Felipe Martins");
            F("email", "fmantipuff@gmail.com");
            F("telefone", "(12) 98122-1043");
            await F("uf_codigo", "26");
            await F("cidade_codigo", "9726");
        }
    });*/

    const F =  async(key, val) => {
        try {
            await _formUserRef.current.Value(key, val);
        } catch(e) {}
    }

    const R = (key, val) =>{
        window.$('input:radio[name="'+key+'"]').filter('[value="' + val + '"]').attr('checked', true);
    }

    const D =  async(key, val) => {
        await window.$("#"+key).val(val);
    }

    const C =  async(key, val) => {
        await window.$("#"+key).attr('checked', val);
    }
    //#endregion Tests


    return (
        <>
            <Menu />
            <Breadcrumb title="Reclame Aqui" list={breadcrumb} />

            <div className="ReclameAqui jp_listing_single_main_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                            <div className="jp_listing_left_sidebar_wrapper">
                                <div className="jp_job_des">
                                    <h2>Ajude a selecionar os melhores</h2>
                                    <br />
                                    <p>O sistema criado é para valorizar a reputação de empresas e profissionais que executam serviços, pois neste canal o cliente poderá colocar avaliação positiva ou avaliação negativa e isso vai influenciar diretamente na qualidade dos serviços prestados. Nosso sistema de reputação garantirá integridade dos clientes e das empresas e profissionais cadastrados dando a eles oportunidade de solucionarem a situação motivo da reclamação.</p>
                                    <p>O sistema de reputação é auditado por nossa equipe e sempre que ocorrer uma reclamação a empresa ou profissional será notificado em seu e-mail cadastrado para que possa fazer as suas argumentações (Defesa) sobre a reclamação e providenciar a solução.</p>
                                    <p> Dúvidas entre em contato com nosso time através dos canais: <b>i2play@gtnp.com.br</b> ou WhatsApp</p>
                                </div>
                              
                                <div className="jp_job_des">
                                    <br /><br />
                                
                                    <h2>Inicie a avaliação agora!</h2>
                                </div>
                                
                                <div className="col-lg-12" style={{marginTop:'30px'}}>
                                    
                                    <div className="jp_contact_inputs_wrapper">
                                        <i className="fa fa-user"></i>
                                        <input id="word" type="text" list="nameslist" value={search.word} placeholder="Buscar por profissional" onChange={_HandlerSearchChange} autoComplete="off"/>
                                        <datalist id="nameslist">
                                            {
                                                names.length > 0 ? (
                                                    names.map((item, i) => (
                                                        <option key={'user' + i} value={item.nome}>{item.cidade}-{item.uf}</option>
                                                    ))
                                                ) : (<></>)
                                            }
                                        </datalist>
                                    </div>

                                    <br />


                                    <div id="positiva_or_negativa" className={selectedUser.user_id!==''?'':'hide'}>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <label><input type="radio" name="p_o_n" value="1" onClick={_HandleRadioClick}/> Avaliação positiva</label>
                                            </div>
                                            <div className="col-lg-6">
                                                <label><input type="radio" name="p_o_n" value="2" onClick={_HandleRadioClick}/> Avaliação negativa</label>
                                            </div>
                                        </div>
                                        <hr />

                                        <div id="compra_or_lipeza" className={isPositive===2?'':'hide'}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <label><input type="radio" name="c_o_l" value="1" onClick={_HandleRadioClick}/> Compra instalação e conserto</label>
                                                </div>
                                                <div className="col-lg-6">
                                                    <label><input type="radio" name="c_o_l" value="2" onClick={_HandleRadioClick}/> Limpeza e higienização</label>
                                                </div>
                                            </div>
                                            <hr />

                                            <div id="compra" className={isConserto===1?'':'hide'}>
                                                <div><label><input type="checkbox" id="c1" value="1"/> 1. Problemas com a máquina comprada</label></div>
                                                <div><label><input type="checkbox" id="c2" value="2"/> 2. Problemas na entrega do equipamento</label></div>
                                                <div><label><input type="checkbox" id="c3" value="3"/> 3. Problemas na Instalação</label></div>
                                                <div><label><input type="checkbox" id="c4" value="4"/> 4. Problema com o prazo de executar e finalizar a instalação</label></div>
                                                <div><label><input type="checkbox" id="c5" value="5"/> 5. Não retornou para finalizar o trabalho</label></div>
                                                <div><label><input type="checkbox" id="c6" value="6"/> 6. Finalização da instalação Inadequada</label></div>
                                                <div><label><input type="checkbox" id="c7" value="7"/> 7. Equipamento não funcionou direito</label></div>
                                                <div><label><input type="checkbox" id="c8" value="8"/> 8. Instalação deixou a deseja</label></div>
                                                <div><label><input type="checkbox" id="c9" value="9"/> 9. Instalação executada fora do padrão</label></div>
                                                <div><label><input type="checkbox" id="c10" value="10"/> 10. Conserto Ineficiente</label></div>
                                                <div><label><input type="checkbox" id="c11" value="11"/> 11. Consertou mas continua com problema</label></div>
                                                <div><label><input type="checkbox" id="c12" value="12"/> 12. Não tinha as ferramentas adequadas</label></div>
                                                <div><label><input type="checkbox" id="c13" value="13"/> 13. Deixou local sujo</label></div>
                                                <div><label><input type="checkbox" id="c14" value="14"/> 14. Não tinha identificação</label></div>
                                                <div><label><input type="checkbox" id="c15" value="15"/> 15. Não tinha identificação nenhuma, sem uniforme ou crachá</label></div>
                                            </div>


                                            <div id="limpeza" className={isConserto===2?'':'hide'}>
                                                <div><label><input type="checkbox" id="l1" value="1"/> 1. A máquina continua suja, só limpou o Filtro</label></div>
                                                <div><label><input type="checkbox" id="l2" value="2"/> 2. Máquina com gotejamento</label></div>
                                                <div><label><input type="checkbox" id="l3" value="3"/> 3. Máquina com mal cheiro</label></div>
                                                <div><label><input type="checkbox" id="l4" value="4"/> 4. Não tinha as ferramentas adequadas</label></div>
                                                <div><label><input type="checkbox" id="l5" value="5"/> 5. Deixou local sujo</label></div>
                                                <div><label><input type="checkbox" id="l6" value="6"/> 6. Não tinha identificação nenhuma, sem uniforme ou crachá</label></div>
                                                <div><label><input type="checkbox" id="l7" value="7"/> 7. Não resolveu o problema que foi contratado</label></div>
                                                <div><label><input type="checkbox" id="l8" value="8"/> 8. Serviço de baixa qualidade (Não INDICO O PROFISSIONAL)</label></div>
                                                <div><label><input type="checkbox" id="l9" value="9"/> 9. Causou danos ao equipamento</label></div>
                                            </div>

                                            <hr />
                                        </div>

                                        <div className={isPositive===0?'hide':''}>
                                            {/*}
                                            <label>Comentários</label>
                                            <textarea id="comments" onChange={(e) => setComment(e.target.value)}></textarea>

                                            <div className="login_btn_wrapper register_btn_wrapper login_wrapper">
                                                <button type="button" className="btn btn-primary av_btn" disabled={comment.length===0}><i className="fa fa-comment"></i> &nbsp;Salvar avaliação</button>
                                            </div>
                                            */}

                                            <div className="jp_job_des">
                                                <h2>Suas Informações</h2>
                                                <br /><br />
                                            </div>

                                            <form name="fuser" onSubmit={(event) => _HandleSubmit('orcamento', event)} autoComplete="off" id="form-contact" className="" noValidate>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        
                                                    <FormGenerator ref={_formUserRef} name={'contato'} url={'/forms/contato.json'} execRef={Exec}></FormGenerator>
                                                    <div style={{clear:'both'}}></div>
                                                    
                                                    <div className="jp_contact_form_btn_wrapper">
                                                        <ul>
                                                            <li><button type="submit" className="btn_contato"><i className="fa fa-plus-circle"></i>&nbsp; ENVIAR</button></li>
                                                        </ul>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>

                                </div>
                            </div>
                           
                            <Share />
                           
                        </div>
                        <Banner />
                    </div>
                </div>
            </div>
        </>
    )
}