/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { HubObjects } from '../../../scripts/HubObjects';
import './index.css';




export default function Menu() {


    return (
        <div className="gc_main_menu_wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 hidden-xs hidden-sm full_width">
                        <div className="gc_header_wrapper">
                            <div className="gc_logo">
                                <LinkContainer to={'/'}>
                                    <a href="index.html"><img src="images/header/logo.png" alt="Logo" title="i2Play" className="img-responsive" /></a>
                                </LinkContainer>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-8 col-sm-12 col-xs-12 center_responsive">
                        <div className="header-area hidden-menu-bar stick" id="sticker">
                           
                            <div className="mainmenu">
                    
                                 <ul className="float_left">
                                    <li className="has-mega gc_main_navigation">
                                        <LinkContainer to={'/'}>
                                            <a className="gc_main_navigation">  
                                                Início&nbsp;{/*<i className="fa fa-angle-down"></i>*/}
                                            </a>
                                        </LinkContainer>
                                    </li>
                                    <li className="has-mega gc_main_navigation"><a className="gc_main_navigation"> Serviços&nbsp;<i className="fa fa-angle-down"></i></a>
                                       
                                        <ul>
                                            <li className="parent"><LinkContainer to={'/Orcamento'}><a>Pedidos de Orçamento</a></LinkContainer></li>
                                            {/*<li className="parent"><LinkContainer to={'/Catalogo'}><a>Catálogo de Serviços</a></LinkContainer></li>*/}
                                            <li className="parent"><LinkContainer to={'/Ranking'}><a>Ranking</a></LinkContainer></li>
                                            <li className="parent"><a href="https://www.refrigeristadofuturo.com.br" target="_blank">Blog</a></li>
                                        </ul>
                                    </li>
                        
									<li className="has-mega gc_main_navigation"><a className="gc_main_navigation"> Profissionais&nbsp;<i className="fa fa-angle-down"></i></a>
        
                                        <ul>
                                            <li className="parent"><LinkContainer to={'/Cadastro'}><a>Cadastro</a></LinkContainer></li>
                                            <li className="parent"><LinkContainer to={'/ComoFunciona'}><a>Como funciona</a></LinkContainer></li>
                                            <li className="parent"><LinkContainer to={'/Planos'}><a>Planos</a></LinkContainer></li>
                                            <li className="parent"><LinkContainer to={'/Videos'}><a>Videos</a></LinkContainer></li>
                                        </ul>
                                    </li>
                                    <li className="has-mega gc_main_navigation"><a className="gc_main_navigation"> Quem somos&nbsp;<i className="fa fa-angle-down"></i></a>
                                      
                                        <ul>
                                            <li className="parent"><LinkContainer to={'/Sobre'}><a>Sobre a i2PLAY</a></LinkContainer></li>
                                            <li className="parent"><LinkContainer to={'/Privacidade'}><a>Uso e privacidade</a></LinkContainer></li>
                                            <li className="parent"><LinkContainer to={'/Parceiros'}><a>Parceiros</a></LinkContainer></li>
                                        </ul>
                                    </li>
                                    <li className="has-mega gc_main_navigation"><a className="gc_main_navigation"> Fale Conosco&nbsp;<i className="fa fa-angle-down"></i></a>
                                      
                                      <ul>
                                          <li className="parent"><LinkContainer to={'/Contato'}><a>Contato</a></LinkContainer></li>
                                          <li className="parent"><LinkContainer to={'/Faq'}><a>Perguntas frequentes</a></LinkContainer></li>
                                          <li className="parent"><LinkContainer to={'/ReclameAqui'}><a>Reclame aqui</a></LinkContainer></li>
                                      </ul>
                                  </li>
                                </ul>
                            </div>
             
                            <header className="mobail_menu">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-xs-6 col-sm-6">
                                            <div className="gc_logo">
                                                <LinkContainer to={'/'}>
                                                    <a><img src="images/header/logo.png" alt="Logo" title="i2play"/></a>
                                                </LinkContainer>
                                            </div>
                                        </div>
                                        <div className="col-xs-6 col-sm-6">
                                            <div className="cd-dropdown-wrapper">
                                                <a className="house_toggle">
													<i className='fa fa-bars fa-2x'></i>
											    </a>
                                                <nav className="cd-dropdown">
                                                <h2><a>i<span>2</span>play</a></h2>
                                                    <a  className="cd-close">Close</a>
                                                    <ul className="cd-dropdown-content">
                    
                                                        <li className="_has-children">
                                                            <LinkContainer to={'/'}>
                                                                <a>Início</a>
                                                            </LinkContainer>
                                                        </li>
                                              

                                                        <li className="has-children">
                                                            <a>Serviços</a>

                                                            <ul className="cd-secondary-dropdown is-hidden">
                                                                <li className="go-back"><a>Menu</a></li>
                                                                <li><LinkContainer to={'/Orcamento'}><a>Pedidos de Orçamento</a></LinkContainer></li>
                                                                <li><LinkContainer to={'/Ranking'}><a>Ranking</a></LinkContainer></li>
                                                                <li><a href="https://www.refrigeristadofuturo.com.br" target="_blank">Blog</a></li>
                                                            </ul>
                                                        
                                                        </li>
                                                       
                                                        <li className="has-children">
                                                            <a>Profissionais</a>

                                                            <ul className="cd-secondary-dropdown is-hidden">
                                                                <li className="go-back"><a>Menu</a></li>
																<li><LinkContainer to={'/Cadastro'}><a>Cadastro</a></LinkContainer></li>
																<li><LinkContainer to={'/ComoFunciona'}><a>Como funciona</a></LinkContainer></li>
																<li><LinkContainer to={'/Planos'}><a>Planos</a></LinkContainer></li>
                                                                <li><LinkContainer to={'/Videos'}><a>Videos</a></LinkContainer></li>
                                                            </ul>
                                                  
                                                        </li>
														<li className="has-children">
                                                            <a>Quem somos</a>

                                                            <ul className="cd-secondary-dropdown is-hidden">
                                                                <li className="go-back"><a>Menu</a></li>
                                                                <li><LinkContainer to={'/Sobre'}><a>Sobre a i2PLAY</a></LinkContainer></li>
																<li><LinkContainer to={'/Privacidade'}><a>Uso e privacidade</a></LinkContainer></li>
																<li><LinkContainer to={'/Parceiros'}><a>Parceiros</a></LinkContainer></li>
                                                            </ul>
                                                           
                                                        </li>
                                                     
                                                        <li className="has-children">
                                                            <a>Fale Conosco</a>

                                                            <ul className="cd-secondary-dropdown is-hidden">
                                                                <li className="go-back"><a>Menu</a></li>
                                                                <li><LinkContainer to={'/Contato'}><a>Contato</a></LinkContainer></li>
                                                                <li><LinkContainer to={'/Faq'}><a>Perguntas frequentes</a></LinkContainer></li>
                                                                <li><LinkContainer to={'/ReclameAqui'}><a>Reclame aqui</a></LinkContainer></li>
                                                            </ul>
                                               
                                                        </li>
                                                       
														<li>
                                                            <LinkContainer to={'/Cadastro'}><a>Cadastro</a></LinkContainer>
                                                           
                                                        </li>
                                                    </ul>

                                                </nav>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </header>
                        </div>
                    </div>
        
                    <div className="col-lg-2 col-md-4 col-sm-12 col-xs-12 hidden-sm hidden-xs">
                        <div className="jp_navi_right_btn_wrapper">
                            <ul>
                                <li>
                                    <LinkContainer to={'/Cadastro'}><a><i className="fa fa-user"></i>&nbsp; CADASTRO</a></LinkContainer><br />
                                    <span style={{fontSize:'14px'}}>Prestador de serviço</span>
                                </li>
                                <li className='hide'><a><i className="fa fa-sign-in"></i>&nbsp; LOGIN</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}