import React, { useEffect, useState, useRef } from 'react';
import $ from 'jquery';
import './index.css';
import validator from 'validator'
import { LinkContainer } from 'react-router-bootstrap';
import { requestForToken } from '../../firebaseInit';
import { Cache } from '../../scripts/Cache';
import { Request } from '../../scripts/Request';

export default function Footer() {
    const [email, setEmail] = useState("");
    const [isTokenFound, setTokenFound] = useState(false);
    const [input, setInput] = useState({});

    const emailRef = useRef("");

    //#region Effects
    useEffect(() => {
        let token = Cache.Get("firebase_token");
        if( token !== "" && token !== undefined && token !== null) {
            
        } else {
            async function gtoken() {
                let data = await requestForToken(setTokenFound);
                if (data) {
                    console.log("Token is", data);
                    Cache.Set("firebase_token", data);
                }
            }
            gtoken();
        }
    }, []);
    //#endregion Effects


    //#region Loaders
    const LoadAddNewsLetter = (data, email) => {   
        window.w_loadObj("#newsletter");

        Promise.resolve(new Request().Run("api/Home/Newsletter", "POST", {token: data, email: email}, "", 0))
            .then((data) => {
                window.swal("Sucesso", "Sua notificações foram ativadas", "success");
                window.w_unloadObj("#newsletter");
            }).catch(() => {
                window.w_unloadObj("#newsletter");
            });
    }
    //#endregion Loaders


    //#region Handler
    const _HandleInputChange = (e) => {
        setInput({...input, [e.currentTarget.id]: e.currentTarget.value});

        if(e.currentTarget.id === "newsletter") emailRef.current = e.currentTarget.value;
    }

    const _HandlerToTop = () => {
        $('html, body').animate({ scrollTop: 0 }, 'fast');
    }

    const _HandleAddNewsLetter = async(email) => {
        let _email = emailRef.current;

        console.log(email, validator.isEmail(_email));
       
        if(validator.isEmail(_email)) {
            let data = await requestForToken(setTokenFound);
            if (data) {
                console.log("Token is", data);
                Cache.Set("firebase_token", data);
                LoadAddNewsLetter(data, _email);
            }
        } else {
            window.swal("Alerta", "E-mail inválido!", "error");
        }
    }
    //#endregion Handler

    return (
        <div className="jp_main_footer_img_wrapper">
            <div className="jp_newsletter_img_overlay_wrapper"></div>
            <div className="jp_newsletter_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="jp_newsletter_text">
                                <img src="images/content/news_logo.png" className="img-responsive" alt="news_logo" />
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                            <div className="jp_newsletter_field">
                                <i className="fa fa-envelope-o"></i><input id="newsletter" name="newsletter" type="text" placeholder="Digite seu E-mail" onChange={_HandleInputChange}/><button type="button" onClick={()=>_HandleAddNewsLetter(email)}>Enviar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="jp_footer_main_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="jp_footer_logo_wrapper">
                                <div className="jp_footer_logo">
                                    <a><img src="images/content/resume_logo.png" alt="footer_logo"/></a>
                                </div>
                            </div>
                        </div>
                        <div className="jp_footer_three_sec_main_wrapper">
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                <div className="jp_footer_first_cont_wrapper">
                                    <div className="jp_footer_first_cont">
                                        <h2>Quem somos</h2>
                                        <p>A i2play foi desenvolvida para unir clientes a profissionais, clientes de forma simples e rápida que precisam de serviços e buscam por profissionais que querem aumentar a sua demanda de serviços. <br /><br />Uma ferramenta completa, dinâmica, que gera benefícios a ambos, pois resolve problemas e gera soluções!</p>
                                        {/*<ul>
                                            <li><i className="fa fa-plus-circle"></i> <a>READ MORE</a></li>
                                        </ul>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                <div className="jp_footer_candidate_wrapper jp_footer_candidate_wrapper2">
                                    <div className="jp_footer_candidate">
                                        <h2>Profissionais</h2>
                                        <ul>
                                            <li><LinkContainer to={'/Cadastro'}><a><i className="fa fa-caret-right" aria-hidden="true"></i> Cadastro</a></LinkContainer></li>
                                            <li><LinkContainer to={'/ComoFunciona'}><a><i className="fa fa-caret-right" aria-hidden="true"></i> Como funciona</a></LinkContainer></li>
                                            <li><LinkContainer to={'/Planos'}><a><i className="fa fa-caret-right" aria-hidden="true"></i> Planos</a></LinkContainer></li>
                                            <li><LinkContainer to={'/Videos'}><a><i className="fa fa-caret-right" aria-hidden="true"></i> Vídeos</a></LinkContainer></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                <div className="jp_footer_candidate_wrapper jp_footer_candidate_wrapper3">
                                    <div className="jp_footer_candidate">
                                        <h2>Negócios</h2>
                                        <ul>
                                            <li><LinkContainer to={'/Parceiros'}><a><i className="fa fa-caret-right" aria-hidden="true"></i> Seja parceiro</a></LinkContainer></li>
                                            <li><LinkContainer to={'/ReclameAqui'}><a><i className="fa fa-caret-right" aria-hidden="true"></i> Reclame Aqui</a></LinkContainer></li>
                                            <li><a href="https://www.refrigeristadofuturo.com.br" target="_blank"><i className="fa fa-caret-right" aria-hidden="true"></i>Blog</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                <div className="jp_footer_candidate_wrapper jp_footer_candidate_wrapper4">
                                    <div className="jp_footer_candidate">
                                        <h2>Links Úteis</h2>
                                        <ul>
                                            <li><LinkContainer to={'/Faq'}><a><i className="fa fa-caret-right" aria-hidden="true"></i> Faq</a></LinkContainer></li>
                                            <li><LinkContainer to={'/Contato'}><a><i className="fa fa-caret-right" aria-hidden="true"></i> Contato</a></LinkContainer></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="jp_bottom_footer_Wrapper">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="jp_bottom_footer_left_cont">
                                            <p>© 2022 - i2play. Todos direitos reservados</p>
                                        </div>
                                        <div className="jp_bottom_top_scrollbar_wrapper">
                                            <a id="return-to-top"><i className="fa fa-angle-up" onClick={_HandlerToTop}></i></a>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div className="jp_bottom_footer_right_cont">
                                            <ul>
                                                <li><a href="https://www.facebook.com/refrigeristadofuturo/" target="_blank"><i className="fa fa-facebook"></i></a></li>
                                                <li><a href="https://www.youtube.com/channel/UCCvSdQpvc3Xk-puXN9OL8gw"  target="_blank"><i className="fa fa-youtube"></i></a></li>
                                                <li><a href="https://www.instagram.com/refrigeristadofuturo/"  target="_blank"><i className="fa fa-instagram"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   
    )
}