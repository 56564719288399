/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';

export default function UserChoose() {

    return(
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="jp_register_section_main_wrapper">
                <div className="jp_regis_left_side_box_wrapper">
                    <div className="jp_regis_left_side_box">
                        <img src="images/content/regis_icon.png" alt="icon" />
                        <h4>Preciso de serviços</h4>
                        <p>Descreva o tipo de serviço que precisa <br />e receba vários orçamentos...</p>
                        <ul>
                            <li>
                                <LinkContainer to={'/Orcamento'}>
                                    <a><i className="fa fa-plus-circle"></i> &nbsp;PEDIR ORÇAMENTO</a>
                                </LinkContainer>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="jp_regis_right_side_box_wrapper">
                    <div className="jp_regis_right_img_overlay"></div>
                    <div className="jp_regis_right_side_box">
                        <img src="images/content/regis_icon2.png" alt="icon" />
                        <h4>Prestador de serviços</h4>
                        <p>Faça para do nosso catálogo e responda <br />vários orçamentos de serviços...</p>
                        <ul>
                            <li>
                                <LinkContainer to={'/Cadastro'}>
                                    <a><i className="fa fa-plus-circle"></i> &nbsp;EFETUAR CADASTRO</a>
                                </LinkContainer>
                            </li>
                        </ul>
                    </div>
                    <div className="jp_regis_center_tag_wrapper">
                        <p>OU</p>
                    </div>
                </div>
            </div>
        </div>
    )
}