/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './SideCategoriesA.css';

export default function SideCategoriesA() {

    return (
        <div className="SideCategoriesA col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="jp_rightside_job_categories_wrapper">
            <div className="jp_rightside_job_categories_heading">
                <h4>Divulgação e Mídia</h4>
            </div>
            <div className="jp_rightside_job_categories_content">
                <img src="/images/banners/257x305/BPSOF 257 x 305 (03).png" alt="" />
 
                {/*<ul>
                    <li><i className="fa fa-caret-right"></i> <a href="#">Graphic Designer <span>(214)</span></a></li>
                    <li><i className="fa fa-caret-right"></i> <a href="#">Engineering Jobs <span>(514)</span></a></li>
                    <li><i className="fa fa-caret-right"></i> <a href="#">Mainframe Jobs <span>(554)</span></a></li>
                    <li><i className="fa fa-caret-right"></i> <a href="#">Legal Jobs <span>(457)</span></a></li>
                    <li><i className="fa fa-caret-right"></i> <a href="#">IT Jobs <span>(1254)</span></a></li>
                    <li><i className="fa fa-plus-circle"></i> <a href="#">View All Categories</a></li>
                </ul>*/}
            </div>
        </div>
    </div>
    );
}