/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './index.css';


export default function Banner() {

    return (
        <div className="Banner col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="jp_rightside_job_categories_wrapper jp_rightside_listing_single_wrapper">
                        <div className="jp_rightside_job_categories_heading">
                            <h4>Programa de afiliados</h4>
                        </div>
                        <div className="jp_jop_overview_img_wrapper">
                            <div className="jp_jop_overview_img">
                                <img src="images/banners/356x309/BP I2PLAY 356 X 309 (02).png" alt="" />
                            </div>
                        </div>

                        {/*<div className="jp_jop_overview_img_wrapper">
                            <div className="jp_jop_overview_img">
                                <img src="images/content/web.png" alt="post_img" />
                            </div>
                        </div>
                        <div className="jp_job_listing_single_post_right_cont">
                            <div className="jp_job_listing_single_post_right_cont_wrapper">
                                <h4>Webstrot Technology</h4>
                                <p>MARKETING JOB</p>
                            </div>
                        </div>
                        <div className="jp_job_post_right_overview_btn_wrapper">
                            <div className="jp_job_post_right_overview_btn">
                                <ul>
                                    <li><a href="#">141 Jobs</a></li>
                                </ul>
                            </div>
                        </div>*/}
                        <div className="jp_listing_overview_list_outside_main_wrapper">
                            <div className="jp_listing_overview_list_main_wrapper">
                                <div className="jp_listing_list_icon">
                                    <i className="fa fa-map-marker"></i>
                                </div>
                                <div className="jp_listing_list_icon_cont_wrapper">
                                    <ul>
                                        <li>Endereço:</li>
                                        <li>Rua Itaboaté, 86 – Res. Vila Olímpia – Taubaté – SP – CEP:12062-717</li>
                                    </ul>
                                </div>
                            </div>
                            {/*<div className="jp_listing_overview_list_main_wrapper jp_listing_overview_list_main_wrapper2">
                                <div className="jp_listing_list_icon">
                                    <i className="fa fa-phone"></i>
                                </div>
                                <div className="jp_listing_list_icon_cont_wrapper">
                                    <ul>
                                        <li>Telefone:</li>
                                        <li>(12) 3211-0390</li>
                                    </ul>
                                </div>
                            </div>*/}
                            <div className="jp_listing_overview_list_main_wrapper jp_listing_overview_list_main_wrapper2">
                                <div className="jp_listing_list_icon">
                                    <i className="fa fa-whatsapp"></i>
                                </div>
                                <div className="jp_listing_list_icon_cont_wrapper">
                                    <ul>
                                        <li>Whatsapp: Central de Atendimento</li>
                                        <li>(12) 98297-1638</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="jp_listing_overview_list_main_wrapper jp_listing_overview_list_main_wrapper2">
                                <div className="jp_listing_list_icon">
                                    <i className="fa fa-envelope"></i>
                                </div>
                                <div className="jp_listing_list_icon_cont_wrapper">
                                    <ul>
                                        <li>E-mail:</li>
                                        <li>i2play@gtnp.com.br</li>
                                    </ul>
                                </div>
                            </div>
                            {/*<div className="jp_listing_right_bar_btn_wrapper">
                                <div className="jp_listing_right_bar_btn">
                                    <ul>
                                        <li><a href="#"><i className="fa fa-plus-circle"></i> &nbsp;Follow Facebook</a></li>
                                        <li><a href="#"><i className="fa fa-plus-circle"></i> &nbsp;Follow NOw!</a></li>
                                    </ul>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}