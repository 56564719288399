/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React,  { useRef }from 'react';
import { useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Cache } from '../../scripts/Cache.js';
import './index.css';

import Menu from '../Header/Menu';
import Breadcrumb from '../Header/Breadcrumb';
import Formulario from './Formulario/index.js';


export default function Orcamento() {

    //#region Affiliate
    const affiliateRef = useRef({affiliate: "", type:""})
    let { Affiliate } = useParams();


    if(Affiliate!=="" && Affiliate!==null &&Affiliate!==undefined) {
        Cache.Set("affiliate", Affiliate);
        affiliateRef.current.affiliate = Affiliate;
    } else {
        affiliateRef.current.affiliate = Cache.Get("affiliate");
    }
    //#endregion Affiliate


    const list = [
        {link:"/", name:"Início"},
        {link:"", name:"Pedido de Orçamento"}
    ];



    return (
        <>
            <Menu />
            <Breadcrumb title="Orçamento" list={list}  />
            <Formulario affiliate={affiliateRef.current.affiliate} />
        </>
    )
}