/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import ReactGA from 'react-ga';
import { Request } from '../../scripts/Request';
import { Currency } from '../../scripts/StringUtils';
import './index.css';

import Menu from '../Header/Menu';
import Breadcrumb from '../Header/Breadcrumb';



export default function Planos() {
    const [pageLoading, setPageLoading] = useState(false);
    const [planos, setPlanos] = useState([]);
    const [breadcrumb, setBreadcrumb] = useState([
        {link:"/", name:"Planos"},
        {link:"", name:"Assinatura sob medida"}
    ]);

    const timerIdleRef = useRef(new Date());
    const loadedRef = useRef(false);



    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        LoadPlanos();
    }, []);

    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);
    //#endregion Effects


    //#region Loaders
    const LoadPlanos = () => {
        setPageLoading(true);
        Promise.resolve(new Request().Run("api/Planos/List", "POST", {}, "", 0*3600))
            .then((data) => {
                setPlanos(data);
                setPageLoading(false);
            }).catch(() => {
                setPageLoading(false);
            });
     }
    //#endregion Loaders



    return (
        <>
            <Menu />
            <Breadcrumb title="Planos" list={breadcrumb} />

            <div className="jp_pricing_main_wrapper">
                <div className="container">
                    <div className="row">
                        {
                            planos.length > 0 ? (
                                planos.splice(0,6).map((item, i) => (
                                    <>
                                         <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <div className="pricing_box1_wrapper pricing_border_box1_wrapper">
                                                <div className="box1_heading_wrapper">
                                                    <h4>{item.name}</h4>
                                                </div>
                                                <div className="price_box1_wrapper">
                                                    <div className={i<4?'price_box1':'price_box3'}>
                                                        <h1><span style={{fontSize:'22px'}}>{Currency(item.valor)}</span></h1>
                                                    </div>
                                                </div>
                                                <div className="pricing_cont_wrapper">
                                                    <div className="pricing_cont">
                                                        <ul>
                                                            <li>{item.obs}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="pricing_btn_wrapper">
                                                    <div className="pricing_btn1">
                                                        <ul>
                                                            <li><a href="https://play.google.com/store/apps/details?id=org.lifequery.i2play" target="_blank">&nbsp;Adquirir no App&nbsp;</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="jp_pricing_label_wrapper">
                                                    <i className="fa fa-star"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))
                            ) : (<></>)
                        }



                        {/*
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="pricing_box1_wrapper pricing_border_box1_wrapper">
                                <div className="box1_heading_wrapper">
                                    <h4>Basic Plan</h4>
                                </div>
                                <div className="price_box1_wrapper">
                                    <div className="price_box1">
                                        <h1>$<span>29</span></h1>
                                    </div>
                                </div>
                                <div className="pricing_cont_wrapper">
                                    <div className="pricing_cont">
                                        <ul>
                                            <li><i className="fa fa-plus-circle"></i> &nbsp;&nbsp;5 Jobs Posting</li>
                                            <li><i className="fa fa-plus-circle"></i> &nbsp;&nbsp;2 Featured jobs</li>
                                            <li><i className="fa fa-plus-circle"></i> &nbsp;&nbsp;1 Renew Jobs</li>
                                            <li><i className="fa fa-plus-circle"></i> &nbsp;&nbsp;10 Days Duration</li>
                                            <li><i className="fa fa-plus-circle"></i> &nbsp;&nbsp;Email Alert</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="pricing_btn_wrapper">
                                    <div className="pricing_btn1">
                                        <ul>
                                            <li><a href="#"><i className="fa fa-plus-circle"></i> Get Started</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="jp_pricing_label_wrapper">
                                    <i className="fa fa-star"></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="pricing_box1_wrapper pricing_border_box2_wrapper">
                                <div className="box1_heading_wrapper box2_heading_wrapper">
                                    <h4>Premium Plan</h4>
                                </div>
                                <div className="price_box1_wrapper">
                                    <div className="price_box2">
                                        <h1>$<span>49</span></h1>
                                    </div>
                                </div>
                                <div className="pricing_cont_wrapper">
                                    <div className="pricing_cont">
                                        <ul>
                                            <li><i className="fa fa-plus-circle"></i> &nbsp;&nbsp;5 Jobs Posting</li>
                                            <li><i className="fa fa-plus-circle"></i> &nbsp;&nbsp;2 Featured jobs</li>
                                            <li><i className="fa fa-plus-circle"></i> &nbsp;&nbsp;1 Renew Jobs</li>
                                            <li><i className="fa fa-plus-circle"></i> &nbsp;&nbsp;10 Days Duration</li>
                                            <li><i className="fa fa-plus-circle"></i> &nbsp;&nbsp;Email Alert</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="pricing_btn_wrapper">
                                    <div className="pricing_btn2">
                                        <ul>
                                            <li><a href="#"><i className="fa fa-plus-circle"></i> Get Started</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="jp_pricing_label_wrapper">
                                    <i className="fa fa-star"></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="pricing_box1_wrapper pricing_border_box3_wrapper">
                                <div className="box1_heading_wrapper box3_heading_wrapper">
                                    <h4>Advanced Plan</h4>
                                </div>
                                <div className="price_box1_wrapper">
                                    <div className="price_box3">
                                        <h1>$<span>79</span></h1>
                                    </div>
                                </div>
                                <div className="pricing_cont_wrapper">
                                    <div className="pricing_cont">
                                        <ul>
                                            <li><i className="fa fa-plus-circle"></i> &nbsp;&nbsp;5 Jobs Posting</li>
                                            <li><i className="fa fa-plus-circle"></i> &nbsp;&nbsp;2 Featured jobs</li>
                                            <li><i className="fa fa-plus-circle"></i> &nbsp;&nbsp;1 Renew Jobs</li>
                                            <li><i className="fa fa-plus-circle"></i> &nbsp;&nbsp;10 Days Duration</li>
                                            <li><i className="fa fa-plus-circle"></i> &nbsp;&nbsp;Email Alert</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="pricing_btn_wrapper">
                                    <div className="pricing_btn3">
                                        <ul>
                                            <li><a href="#"><i className="fa fa-plus-circle"></i> Get Started</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="jp_pricing_label_wrapper">
                                    <i className="fa fa-star"></i>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="pricing_box1_wrapper pricing_border_box1_wrapper">
                                <div className="box1_heading_wrapper">
                                    <h4>Basic Plan</h4>
                                </div>
                                <div className="price_box1_wrapper">
                                    <div className="price_box1">
                                        <h1>$<span>29</span></h1>
                                    </div>
                                </div>
                                <div className="pricing_cont_wrapper">
                                    <div className="pricing_cont">
                                        <ul>
                                            <li><i className="fa fa-plus-circle"></i> &nbsp;&nbsp;5 Jobs Posting</li>
                                            <li><i className="fa fa-plus-circle"></i> &nbsp;&nbsp;2 Featured jobs</li>
                                            <li><i className="fa fa-plus-circle"></i> &nbsp;&nbsp;1 Renew Jobs</li>
                                            <li><i className="fa fa-plus-circle"></i> &nbsp;&nbsp;10 Days Duration</li>
                                            <li><i className="fa fa-plus-circle"></i> &nbsp;&nbsp;Email Alert</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="pricing_btn_wrapper">
                                    <div className="pricing_btn1">
                                        <ul>
                                            <li><a href="#"><i className="fa fa-plus-circle"></i> Get Started</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="jp_pricing_label_wrapper">
                                    <i className="fa fa-star"></i>
                                </div>
                            </div>
                        </div>
                        */}
                    </div>
                </div>
            </div>
        </>
    )

}