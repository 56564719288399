/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './index.css';


export default function Share() {

    return (
        <div className="jp_listing_left_bottom_sidebar_wrapper">
            <div className="jp_listing_left_bottom_sidebar_social_wrapper">
                <ul className="hidden-xs">
                    <li>Redes :</li>
                    <li><a href="https://www.facebook.com/refrigeristadofuturo/" target="_blank"><i className="fa fa-facebook"></i></a></li>
                    <li><a href="https://www.youtube.com/channel/UCCvSdQpvc3Xk-puXN9OL8gw"  target="_blank"><i className="fa fa-youtube"></i></a></li>
                    <li><a href="https://www.instagram.com/refrigeristadofuturo/"  target="_blank"><i className="fa fa-instagram"></i></a></li>
                </ul>
            </div>
        </div>
    )
}