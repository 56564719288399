/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import ReactGA from 'react-ga';
import './index.css';

import Menu from '../Header/Menu';
import Breadcrumb from '../Header/Breadcrumb';
import Banner from '../Banner';
import Share from '../Banner/Share';


export default function ComoFunciona() {
    const [pageLoading, setPageLoading] = useState(false);
    const [breadcrumb, setBreadcrumb] = useState([
        {link:"/", name:"Início"},
        {link:"", name:"Entenda o processo"}
    ]);

    const timerIdleRef = useRef(new Date());
    const loadedRef = useRef(false);

    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        let timer = setTimeout(() => { Init() }, 1000);

        return () => {
            clearTimeout(timer);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    }, []);

    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);
    //#endregion Effects


    //#region Init
     const Init = () => {
        if(!loadedRef.current) {
            loadedRef.current = true;
            
        }
    }
    //#endregion Init


    return (
        <>
            <Menu />
            <Breadcrumb title="Como Funciona" list={breadcrumb} />

            <div className="jp_listing_single_main_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                            <div className="jp_listing_left_sidebar_wrapper">
                                <div className="jp_job_des">
                                    <h2>Como funciona o i2play (para profissionais)</h2>
                                    <br />
                                    <p>Você se cadastra gratuitamente e durante 30 dias poderá receber orçamentos diretamente no seu celular via aplicativo i2play.</p>
                                    <p>Você poderá responder aos orçamentos recebidos diretamente ao cliente sem intermediários.</p>
                                    <p>Após 30 dias você continuará recebendo orçamentos, mas terá que optar por um dos nossos planos para ter acesso e responde-los. Acesse agora os nosso planos e veja que é acessível, fácil e seu acesso é liberado instantaneamente e automaticamente, após confirmação do seu pagamento</p>
                                    <p>Dica: Quanto mais divulgar a sua página pessoal (Link personalizado) i2play, mais chances você terá de receber solicitações de orçamento, divulgue em suas redes sociais, sites, blog, anúncios etc...</p>
                                    <p>Rapidez em responder faz toda a diferença, pois lembre-se que outras empresas também receberão o mesmo orçamento, por isso é importante você ficar atento as notificações do app i2play e responder aos orçamentos com brevidade para que seu orçamento seja recebido no whatsapp do seu futuro cliente.</p>
                                    <p>Quer se destacar em sua cidade, entre em contato com nosso departamento de marketing para potencializarmos o número de orçamentos que estará recebendo. Veja agora com nosso time através dos canais: <b>i2play@gtnp.com.br</b> ou WhatsApp</p>
                                    <p>Você também pode solicitar material de divulgação para divulgar em suas redes sociais, sites, blog, anúncios e seus potenciais clientes te encontrarem!</p>
                                </div>
                             
                                
                            </div>
                           
                            <Share />
                           
                        </div>
                        <Banner />
                    </div>
                </div>
            </div>
        </>
    )
}