/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef, useCallback} from 'react';
import { HubObjects } from '../../../scripts/HubObjects';
import { Request } from '../../../scripts/Request';


export default function Search() {
    const component_name = "header/search";
    const [search, setSearch] = useState({word: "", uf_codigo: "", cidade_codigo: ""});
    const [estados, setEstados] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [words, setWords] = useState([]);
    const [pageLoading, setPageLoading] = useState(false);
    const [blockCidade, setBlockCidade] = useState(false);


    const searchRef = useRef({});
    const timerIdleRef = useRef(new Date());

    const [input, setInput] = useState({});
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);


    //#region Effects
    useEffect(() => {
        let timer = setTimeout(() => { Init() }, 1000);

        return () => {
            clearTimeout(timer);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    }, []);


    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);


    //#endregion Effects


    //#region Init
    const Init = () => {
        LoadEstados();
        LoadWords();
    }
    //#endregion Init


    //#region Loaders
    const LoadEstados = async() => {
        window.w_loadObj("#uf_codigo");

        Promise.resolve(new Request().Run("/json/estados.json", "GET", {}, "", 3600*1000*24))
            .then((data) => {
                setEstados(data);
                window.w_unloadObj("#uf_codigo");
            }).catch(() => {
                window.w_unloadObj("#uf_codigo");
            });
    }


    const LoadCidades = async(uf_codigo) => {
        window.w_loadObj("#cidade_codigo")

        Promise.resolve(new Request().Run("api/Home/Cidades?uf_codigo="+uf_codigo, "GET", {}, "", 3600*1000*24))
            .then((data) => {
                if(data) {
                    setCidades(data); 
                    searchRef.current.cidade_codigo = data[0].id;
                    setSearch(searchRef.current);              
                }
                window.w_unloadObj("#cidade_codigo");
            }).catch(() => {
                window.w_unloadObj("#cidade_codigo");
            });
    }


    const LoadWords = () => {
        Promise.resolve(new Request().Run("api/Home/Termos", "GET", {}, "", 3600*1000*24))
            .then((data) => {
                setWords(data);
            }).catch(() => {
                
            });
     }
    //#endregion Loaders


    //#region Handlers
    const _HandleInputChange = (e) => {
        setInput({...input, [e.currentTarget.id]: e.currentTarget.value});

        let _search = { ...search};
        _search[e.currentTarget.id] = e.currentTarget.value;
        searchRef.current = _search;
        setSearch(_search);

        if(e.currentTarget.id === "uf_codigo") {
            LoadCidades(e.currentTarget.value); 
        } 
    }
    

    const _HandlerSearch = () => {
        HubObjects.Exec("HOME_CONTENT_LOJAS", Object.assign({}, search));
    }
    //#endregion Handlers

    
    return (
        <div className="jp_banner_heading_cont_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="jp_job_heading_wrapper">
                            <div className="jp_job_heading">
                                <h1><span>CONECTE-SE a</span> Profissionais</h1>
                                <p>Encontre profissionais & serviços de refrigeração - Peça seu Orçamento</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="jp_header_form_wrapper">
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <input id="word" name="word"  type="text" placeholder="Buscar por um termo"  onChange={_HandleInputChange}/>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                <div className="jp_form_location_wrapper">
                                    <i className="fa fa-dot-circle-o first_icon"></i>
                                    <select id="uf_codigo" name="uf_codigo" onChange={_HandleInputChange} defaultValue={''} value={search.uf_codigo}>
                                        <option value="" disabled>Estado</option>
                                        {estados.map((option, i) => (
                                            <option key={'opt_' + i} value={option.id} selected={option.selected === true}>{option.name}</option>
                                        ))}
                                    </select>
                                <i className="fa fa-angle-down second_icon"></i>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                <div className="jp_form_exper_wrapper">
                                    <i className="fa fa-dot-circle-o first_icon"></i>
                                    <select id="cidade_codigo" name="cidade_codigo" onChange={_HandleInputChange} disabled={search.uf_codigo===""} defaultValue={''} value={search.cidade_codigo}>
                                    <option value="" disabled>Cidade</option>
                                        {cidades.map((option, i) => (
                                            <option key={'city_' + i} value={option.id} selected={option.selected === true}>{option.name}</option>
                                        ))}
                                    </select>
                                    <i className="fa fa-angle-down second_icon"></i>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                                <div className="jp_form_btn_wrapper">
                                    <ul>
                                        <li><a onClick={_HandlerSearch}><i className="fa fa-search"></i> Buscar</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="jp_banner_main_jobs_wrapper">
                            <div className="jp_banner_main_jobs">
                                <ul>
                                    <li><i className="fa fa-tags"></i> Serviços mais buscados :</li>
                                    {
                                        words.map((item, i) => (
                                            <li key={'word'+i}><a>{item}{i<(words.length-1)?',':''}</a></li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}