/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import ReactGA from 'react-ga';
import './index.css';

import Menu from '../Header/Menu';
import Breadcrumb from '../Header/Breadcrumb';
import Banner from '../Banner';
import Share from '../Banner/Share';


export default function Faq() {
    const [pageLoading, setPageLoading] = useState(false);
    const [breadcrumb, setBreadcrumb] = useState([
        {link:"/", name:"Faq"},
        {link:"", name:"Perguntas Frequentes"}
    ]);

    const timerIdleRef = useRef(new Date());
    const loadedRef = useRef(false);

    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        let timer = setTimeout(() => { Init() }, 1000);

        return () => {
            clearTimeout(timer);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    }, []);

    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);
    //#endregion Effects


    //#region Init
     const Init = () => {
        if(!loadedRef.current) {
            loadedRef.current = true;
            
        }
    }
    //#endregion Init


    return (
        <>
            <Menu />
            <Breadcrumb title="Dúvidas" list={breadcrumb} />

            <div className="jp_listing_single_main_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                            <div className="jp_listing_left_sidebar_wrapper">
                                <div className="jp_job_des">
                                    <h2>FAQ - Perguntas frequentes</h2>
                                    <br /><br />
                                    <b>1.	O QUE É A I2PLAY PARA OS CLIENTES?</b><br />
                                    <b>Resposta:</b> É uma plataforma de orçamentos gratuitos que reúne empresas e profissionais qualificados para executarem o serviço que os clientes estão buscando.<br /><br />
                                    
                                    <b>2.	O QUE É A I2PLAY PARA EMPRESAS E PROFISISONAIS DE REFRIGERAÇÃO, CLIMATIZAÇÃO E AR CONDICIONADO?</b><br />
                                    <b>Resposta:</b>É uma ferramenta que capta orçamentos de clientes que precisam de um serviço e estão buscando empresas e profissionais na sua cidade ou sua área de atuação e disponibiliza no aplicativo. <br /><br />

                                    <b>3.	COMO FAÇO PARA RECEBER ORÇAMENTOS?</b><br />
                                    <b>Resposta:</b> Basta estar cadastrado e baixar o app ou acessar via web<br /><br />

                                    <b>4.	PRONTO, FIZ MEU CADASTRO NA I2PLAY E AGORA O QUE DEVO FAZER?</b><br />
                                    <b>Resposta:</b> É um sistema que destaca e valoriza algumas características da sua empresa<br /><br />
                                    Você deve usar o link de bônus de engajamento, desta forma os clientes farão seus orçamentos via i2play e também gerarão descontos a você pela divulgação. Desta forma você pode ter até 100% de sala assinatura do seu plano gratuita.<br />
                                    Outro passo importante é trazer profissionais para a ferramenta desta forma a credibilidade aumenta, pois se o cliente inserir uma solicitação de orçamento e não tiver um profissional o cliente não terá resposta, por isso divulgue seu link Bônus de Afiliado e a cada inscrito você também recebe bônus e desconto na sua assinatura!

                                    <br /><br />

                                    <b>5.	APÓS QUANTOS DIAS DEPOIS DE FAZER MEU CADASTRO PRECISO ASSINAR UM PLANO?</b><br />
                                    <b>Resposta:</b> 30 dias <br /><br /> 


                                    <b>6.	COMO RESPONDO O ORÇAMENTO AO CLIENTE?</b><br />
                                    <b>Resposta:</b> Através do app i2play você recebe o orçamento gerado pelo cliente de sua área de atuação e ao responde-lo o seu orçamento irá diretamente para o whatsapp do cliente que solicitou e poderá interagir <br /><br /> 


                                    <b>7.	O QUE É RANKING?</b><br />
                                    <b>Resposta:</b>  É um sistema que destaca e valoriza algumas características da sua empresa. Quanto mais qualificações você tiver melhor ranqueado você estará.<br /><br />

                                    <b>8.	O QUE É LINK DE AFILIADO?</b><br />
                                    <b>Resposta:</b> É um link personalizado disponibilizado a cada prestador de serviço para poder indicar a plataforma e receber benefícios de descontos no seu plano de assinatura. Quanto mais divulgar mais profissionais mais desconto. Pode ser divulgado em redes sociais, sites, blogs, whatsapp etc... Você pode solicitar via whatsapp as artes gratuitamente que disponibilizaremos!<br /><br />

                                    <b>9.	O QUE É LINK DE ENGAJAMENTO?</b><br />
                                    <b>Resposta:</b> É um link personalizado disponibilizado a cada prestador de serviço para poder divulgar o serviço de orçamentos gratuitos e com isso atrair mais clientes. Pode ser divulgado em redes sociais, sites, blogs, whatsapp etc... Você pode solicitar via whatsapp as artes gratuitamente que disponibilizaremos!<br /><br />

                                    <b>10.	COMO CONSIGO ARTES PARA DIVULGAR A PLATAFORMA?</b><br />
                                    <b>Resposta:</b>Você pode solicitar via whatsapp as artes gratuitamente que disponibilizaremos!<br /><br />

                                    <b>11.	O QUE É RECLAME AQUI?</b><br />
                                    <b>Resposta:</b> Sistema de reputação positiva ou negativa gerada pelo seu cliente! Ele após o serviço pode te avaliar basta solicitar que o mesmo faça enviando o link a ele desta forma sua credibilidade aumenta e outros clientes verão isso!<br /><br />

                                    <b>12.	POSSO CADASTRAR MINHA EMPRESA MAIS DE UMA VEZ?</b><br />
                                    <b>Resposta:</b> É permitido um cadastro por CPF/CNPJ, pois é o suficiente para você receber a solicitação/orçamento do cliente em seu app I2PLAY. <br /><br />

                                    <b>13.	EXISTE LIMITE DE ORÇAMENTOS POR EMPRESA?</b><br />
                                    <b>Resposta:</b> Não, toda vez que um cliente da sua área de atuação fizer a solicitação de um orçamento<br /><br />

                                    <b>14.	RECEBO ORÇAMENTO SEM PLANO ASSINATURA ATIVO?</b><br />
                                    <b>Resposta:</b> Sim você recebe, mas não terá acesso aos dados para responder, somente após ativação do plano de assinatura.<br /><br />

                                    <b>15.	QUANTOS ORÇAMENTO UM CLIENTE PODE RECEBER POR UMA DEMANDA GERADA?</b><br />
                                    <b>Resposta:</b> Nosso sistema está pré definido para enviar até 30 orçamentos. Isso vai depender do número de profissionais da região onde você está localizado.<br /><br />
                                
                                    <b>16.	QUAL A DIFERENÇA DO PLANO BÁSICO PARA O PLANO PLUS?</b><br />
                                    <b>Resposta:</b> O plano Básico você recebe orçamentos como todos os demais prestadores de serviços, já no plano Plus você receberá 2 horas antes de qualquer prestador de serviço. Com isso você poderá ter uma dianteira importante para aprovação de seu orçamento.<br /><br />
                                
                                    <b>17.	COMO INDICAR PARA CLIENTES?</b><br />
                                    <b>Resposta:</b> Simples vá na opção bônus e copie seu código de engajamento, desta forma clientes cadastrados gerarão descontos na sua assinatura. Solicite no whatsapp o combo de artes para divulgação para clientes.<br /><br />
                                
                                    <b>18.	COMO INDICO PARA PARCEIROS DA REFRIGERAÇÃO?</b><br />
                                    <b>Resposta:</b> Simples vá na opção bônus e copie seu código de afiliado, desta forma parceiros cadastrados gerarão descontos na sua assinatura. Solicite no whatsapp o combo de artes para divulgação para parceiros.<br /><br />
                                
                                    <b>19.	ONDE DEVO INDICAR?</b><br />
                                    <b>Resposta:</b> Redes sociais, anúncios gratuitos, pagina web, whatsapp e outros meios, pois cada vez que divulgar vai aumentar suas chances de receber mais orçamentos e ainda de ganhar descontos na sua assinatura.<br /><br />
                                
                                    <b>20.	EXISTEM ARTES PARA INDICAR NAS REDES SOCIAIS?</b><br />
                                    <b>Resposta:</b> Sim existem alguns modelos que desenvolvemos e que você pode usar, mas sempre sugerimos que você crie a sua própria identidade. Solicite no whatsapp o combo de artes para divulgação<br /><br />
                                
                                    <b>21.	POSSO DESENVOLVER MINHAS ARTES?</b><br />
                                    <b>Resposta:</b> Sim esta é a nossa recomendação, mas nada impede de que você possa usar nossos modelos. Para solicitar basta nos chamar no whatsapp<br /><br />
                                
                                    <b>22.	COMO RECEBO OS ORÇAMENTOS?</b>
                                    <b>Resposta:</b> Seus orçamentos serão disponibilizados no app I2PLAY na tela principal opção orçamentos.<br /><br />

                                    <b>23.	COMO RESPONDO OS ORÇAMENTOS?</b><br />
                                    <b>Resposta:</b> Você responderá diretamente pelo app i2play que conectará no whatsapp do cliente aí por diante suas tratativas serão via whatsapp diretamente. <br /><br />
                                
                                    <b>24.	COMO EU MELHORO MINHAS CHANCES DE RECEBER ORÇAMENTOS?</b><br />
                                    <b>Resposta:</b> Divulgue em todas as plataformas que puder e para sua lista de clientes informando que o cliente recebe em minutos orçamento gratuito. <br /><br />
                                </div>
                             
                                
                            </div>
                           
                            <Share />
                           
                        </div>
                        <Banner />
                    </div>
                </div>
            </div>
        </>
    )
}