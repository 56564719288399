import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Cache } from '../../scripts/Cache.js';
import ReactGA from 'react-ga';

import Content from './Content';
import Header from '../Header/';
import Tranding from './Tranding';
import Counters from './Counters';
import Offerings from './Offerings';
import Testimonials from './Testimonials';
import Plans from './Plans';
import AppDownloads from './AppDownload';


export default function Home() {

    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])
    //#end region Effects


    //#region Affiliate
    let { Affiliate } = useParams();

    if(Affiliate!=="" && Affiliate!==null &&Affiliate!==undefined) {
        Cache.Set("affiliate", Affiliate);
    }
    //#endregion Affiliate

    return (
        <>   
             <Header />
             {/*<Tranding />*/}
            <Content />
            {/*<Counters />
            <Offerings />
            <Testimonials />
            <Plans />*/}
            <AppDownloads />
        </>
    );
}