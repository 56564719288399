import React, {useState} from 'react';
import Footer from './components/Footer';

export default function Layout({ children }) {

  return (
      <React.Fragment>
        {children}
        <Footer />
      </React.Fragment>
  )
}