import React, { useState, useEffect } from 'react';
import RouteManager from './RouteManager';
import { BrowserRouter as Router} from 'react-router-dom';

import { AuthProvider } from './Context/AuthContext';
import { onMessageListener } from "./firebaseInit";
import Notifications from "./components/Notifications/Notifications";
import ReactNotificationComponent from "./components/Notifications/ReactNotification";



const App = () => {

  return (
    <>
    <Router>
      <AuthProvider>
        <RouteManager />
      </AuthProvider>
    </Router>
  </>
  )
}

export default App;

