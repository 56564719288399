import React, { useState } from 'react';
import Breadcrumb from '../Header/Breadcrumb';
import Menu from '../Header/Menu';
import Formulario from './Formulario';
import './index.css';


export default function Contato() {
    const [breadcrumb, setBreadcrumb] = useState([
        {link:"/", name:"Início"},
        {link:"", name:"Formulário de contato"}
    ]);


    return (
        <>
        <Menu />
        <Breadcrumb title="Contato" list={breadcrumb} />
        <Formulario />
        </>
    )
}