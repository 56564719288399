/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import ReactGA from 'react-ga';
import './index.css';

import Menu from '../Header/Menu';
import Breadcrumb from '../Header/Breadcrumb';
import Banner from '../Banner';
import Share from '../Banner/Share';


export default function Parceiros() {
    const [pageLoading, setPageLoading] = useState(false);
    const [breadcrumb, setBreadcrumb] = useState([
        {link:"/", name:"Início"},
        {link:"", name:"Seja um parceiro"}
    ]);

    const timerIdleRef = useRef(new Date());
    const loadedRef = useRef(false);

    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        let timer = setTimeout(() => { Init() }, 1000);

        return () => {
            clearTimeout(timer);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            }
        }
    }, []);

    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);
    //#endregion Effects


    //#region Init
     const Init = () => {
        if(!loadedRef.current) {
            loadedRef.current = true;
            
        }
    }
    //#endregion Init


    return (
        <>
            <Menu />
            <Breadcrumb title="Parceiros" list={breadcrumb} />

            <div className="jp_listing_single_main_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                            <div className="jp_listing_left_sidebar_wrapper">
                                <div className="jp_job_des">
                                    <h2>Seja um parceiro</h2>
                                    <br />
                                    <p>Está buscando um canal poderoso para impulsionar as vendas da sua empresa de refrigeração, climatização, ar condicionado ou linha branca?</p>
                                    <p>O i2play é a plataforma ideal para você! Com uma audiência altamente engajada e focada em soluções para o lar e para o dia a dia, somos o ponto de encontro perfeito entre profissionais do setor e clientes em busca de serviços e produtos de qualidade.</p>
                                    <p>Posicione a sua marca e a deixe em evidência para milhares de potenciais compradores.</p>
                                    <p><b>Por que anunciar no i2play?</b><br /><br />
                                   
                                    • &nbsp; &nbsp; Alcance Direto: Conecte-se diretamente com consumidores que já demonstram interesse em seus produtos e serviços, aumentando suas chances de conversão.<br />
                                    • &nbsp; &nbsp; Visibilidade Máxima: Seja visto por milhares de pessoas que buscam ativamente por soluções na área de refrigeração, climatização e linha branca.<br />
                                    • &nbsp; &nbsp; Segmentação Precisa: Alcance o público ideal para o seu negócio.<br />
                                    
                                    <br />
                                    Aqui seus clientes irão te encontrar, sejam eles clientes finais (consumidores) ou prestadores de serviços autônomos ou profissionais e empresas do ramo de refrigeração, climatização, ar-condicionado e linha branca. 
                                    </p>

                                    <p>Não perca essa oportunidade de impulsionar seu negócio!</p>
                                    <p>Anuncie no i2play e alcance novos clientes, aumente suas vendas e fortaleça sua marca. Entre em contato conosco e descubra as melhores opções de anúncios para o seu negócio.</p>
                                    <p>Em pouco tempo, seremos a primeira e mais eficiente plataforma de buscas para o setor de refrigeração, climatização, ar-condicionado e linha branca</p>
                                    <p>Fale conosco através do i2play@gtnp.com.br ou WhatsApp.</p>
                                </div>
                             
                                
                            </div>
                           
                            <Share />
                           
                        </div>
                        <Banner />
                    </div>
                </div>
            </div>
        </>
    )
}