/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './SideAdvice.css';

export default function SideAdvice() {

    return (
        <div className="SideAdvice col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="jp_rightside_career_wrapper">
                <div className="jp_rightside_career_heading">
                    <h4>Super Promo</h4>
                </div>
                <div className="jp_rightside_career_main_content">
                    <a href="https://www.rededac.com.br" target="_blank"><img src="/images/banners/257x437/1 - (257X 433).png" alt="" /></a>
                </div>
            </div>
        </div>
    );
}