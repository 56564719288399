/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import '../../../js/owl.carousel.js';
import './Hiring.css';

export default function Hiring() {

    useEffect(() => {
        window.$('.jp_hiring_slider_wrapper .owl-carousel').owlCarousel({
            loop: true,
            margin: 10,
            autoplay:true,
            responsiveClass: true,
            smartSpeed: 1200,
            navText : ['<i class="fa fa-arrow-circle-o-left" aria-hidden="true"></i>','<i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>'],
            responsive: {
              0: {
                items: 1,
                nav: true
              },
              600: {
                items: 2,
                nav: true
              },
              1000: {
                items: 4,
                nav: true,
                loop: true,
                margin: 20
              }
            }
          })
     }, []);

     return(
        <div className="Hiring col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="jp_hiring_slider_main_wrapper">
            <div className="jp_hiring_heading_wrapper">
                <h2>Divulgação e Mídia</h2>
            </div>
            <div className="jp_hiring_slider_wrapper">
                <div className="owl-carousel owl-theme">
                    <div className="item">
                        <a href="https://www.doutordoarcondicionado.com.br" target="_blank"><img src="images/banners/177x253/1.png" alt="" /></a>
                    </div>

                    <div className="item">
                        <a href="" target="_blank"><img src="images/banners/177x253/2.png" alt="" /></a>
                    </div>

                    <div className="item">
                        <a href="https://www.conectarplay.com" target="_blank"><img src="images/banners/177x253/3.png" alt="" /></a>
                    </div>

                    <div className="item">
                        <img src="images/banners/177x253/4.png" alt="" />
                    </div>

                    <div className="item">
                        <img src="images/banners/177x253/5.png" alt="" />
                    </div>

                    <div className="item">
                        <img src="images/banners/177x253/6.png" alt="" />
                    </div>

                    {/* 
                    <div className="item">
                        <div className="jp_hiring_content_main_wrapper">
                            <div className="jp_hiring_content_wrapper">
                                <img src="images/content/hiring_img1.png" alt="hiring_img" />
                                <h4>Akshay INC.</h4>
                                <p>(NewYork)</p>
                                <ul>
                                    <li><a>4 Opening</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="jp_hiring_content_main_wrapper">
                            <div className="jp_hiring_content_wrapper">
                                <img src="images/content/hiring_img2.png" alt="hiring_img" />
                                <h4>Akshay INC.</h4>
                                <p>(NewYork)</p>
                                <ul>
                                    <li><a>4 Opening</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="jp_hiring_content_main_wrapper">
                            <div className="jp_hiring_content_wrapper">
                                <img src="images/content/hiring_img3.png" alt="hiring_img" />
                                <h4>Akshay INC.</h4>
                                <p>(NewYork)</p>
                                <ul>
                                    <li><a href="#">4 Opening</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="jp_hiring_content_main_wrapper">
                            <div className="jp_hiring_content_wrapper">
                                <img src="images/content/hiring_img4.png" alt="hiring_img" />
                                <h4>Akshay INC.</h4>
                                <p>(NewYork)</p>
                                <ul>
                                    <li><a href="#">4 Opening</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>*/}
                </div>
            </div>
        </div>
    </div>
     )
}