/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

export default function AppDownloads() {

    return (
        <>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 spacer"></div>
            <div className="jp_downlord_main_wrapper">
                <div className="jp_downlord_img_overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 hidden-sm hidden-xs">
                            <div className="jp_down_mob_img_wrapper">
                                <img src="images/content/mobail.png" alt="mobail_img" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div className="ss_download_wrapper_details">
                                <h1><span>Download</span><br />i2play App Agora!</h1>
                                <p>Para profissionais receberem e responderem orçamentos</p>
                                <a href="https://app.i2play.com.br" target="_blank" className="ss_appstore"><span><i className="fa fa-apple" aria-hidden="true"></i></span> App Store</a>
                                <a href="https://play.google.com/store/apps/details?id=org.lifequery.i2play"  target="_blank" className="ss_playstore"><span><i className="fa fa-android" aria-hidden="true"></i></span> Play Store</a>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 visible-sm visible-xs">
                            <div className="jp_down_mob_img_wrapper">
                                <img src="images/content/mobail.png" className="img-responsive" alt="mobail_img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}